import { CLOUD_FRONT_URL } from 'config/constants'
import Image from 'next/image'

const RfxLoader = () => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="relative h-40 w-40">
        <Image
          alt="rfx-loader"
          className="object-cover"
          fill
          priority
          src={`${CLOUD_FRONT_URL}/images/rfx-loader.gif`}
        />
      </div>
    </div>
  )
}

export default RfxLoader
