import { gql } from '@apollo/client'
import { getWrappedToken } from 'config/tokens'
import dayjs from 'dayjs'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { TokensData, parseContractPrice } from 'domain/synthetics/tokens'
import { BigNumber, ethers } from 'ethers'
import { getByKey } from 'gmx/lib/objects'
import { getSyntheticsGraphClient } from 'gmx/lib/subgraph'
import json from 'json5'
import { useMemo } from 'react'
import useSWR from 'swr'
import { isIncreaseOrderType, isSwapOrderType } from '../orders'
import { getSwapPathOutputAddresses } from '../trade/utils'
import {
  PositionTradeAction,
  RawTradeAction,
  SwapTradeAction,
  TradeAction,
} from './types'

export type TradeHistoryResult = {
  tradeActions?: TradeAction[]
  isLoading: boolean
}

export function useTradeHistory(
  chainId: number,
  p: {
    account: string | null | undefined
    forAllAccounts?: boolean
    marketsInfoData?: MarketsInfoData
    tokensData?: TokensData
    pageIndex: number
    pageSize: number
    isOrderExecuted?: boolean
    filterByValue?: string
    isSwap?: boolean
    startDate?: Date
    endDate?: Date
    activeFilter?: string
    includeExcludeFilters?: {
      includes: string[]
      excludes: string[]
    }
  },
) {
  const {
    pageIndex,
    pageSize,
    marketsInfoData,
    tokensData,
    account,
    forAllAccounts,
    activeFilter,
    includeExcludeFilters,
  } = p

  const client = getSyntheticsGraphClient(chainId)

  const key =
    chainId && client && (account || forAllAccounts)
      ? [
          chainId,
          'useTradeHistory',
          account,
          forAllAccounts,
          pageIndex,
          pageSize,
          p.filterByValue,
          p.isSwap,
          p.startDate,
          p.endDate,
          activeFilter,
          includeExcludeFilters?.excludes,
          includeExcludeFilters?.includes,
        ]
      : null

  const getSizeFilter = (filterByValue: string) => {
    if (!filterByValue) {
      return
    }
    if (filterByValue === 'ALL') {
      return {}
    }
    if (filterByValue === 'SHRIMP') {
      return { sizeDeltaUsd_lt: '100000000000000000000000000000000' }
    }
    if (filterByValue === 'SHARK') {
      return {
        and: [
          { sizeDeltaUsd_gt: '100000000000000000000000000000000' },
          { sizeDeltaUsd_lt: '300000000000000000000000000000000' },
        ],
      }
    }
    if (filterByValue === 'WHALE') {
      return { sizeDeltaUsd_gt: '300000000000000000000000000000000' }
    }
  }

  const stringify = (object: any) => {
    return json.stringify(object, {
      quote: '"',
    })
  }

  const getQuery = (
    isSwap: boolean,
    startDate?: Date,
    endDate?: Date,
    filters?: { includes: string[]; excludes: string[] },
  ) => {
    const timestamp: Record<string, number> = {}
    const includeExclude: Record<string, string[]> = {}
    let eventName
    if (startDate && endDate) {
      timestamp.timestamp_gte = dayjs(startDate).unix()
      timestamp.timestamp_lt = dayjs(endDate).unix()
    }
    if (activeFilter && !isSwap) {
      if (activeFilter === 'Modified Orders') {
        eventName = 'OrderUpdated'
      }
      if (activeFilter === 'Cancelled Orders') {
        eventName = 'OrderCancelled'
      }
    }

    if (filters) {
      if (filters.excludes.length) {
        includeExclude.eventName_not_in = filters.excludes
      }
      if (filters.includes.length) {
        includeExclude.eventName_in = filters.includes
      }
    }

    if (isSwap) {
      return {
        account: account!.toLowerCase(),
        orderType: '0',
        ...timestamp,
        ...includeExclude,
      }
    } else {
      return {
        account: account!.toLowerCase(),
        orderType_not_in: ['0', '1'],
        eventName,
        ...timestamp,
        ...includeExclude,
      }
    }
  }

  const { data, error } = useSWR<RawTradeAction[]>(key, {
    fetcher: async () => {
      const skip = pageIndex * pageSize
      const first = pageSize
      const query = gql(`{
        tradeActions(
            skip: ${skip},
            first: ${first},
            orderBy: transaction__timestamp,
            orderDirection: desc,
            ${
              !forAllAccounts && account
                ? `where:  ${stringify(getQuery(p?.isSwap || false, p.startDate, p.endDate, includeExcludeFilters))}`
                : `where: ${stringify(getSizeFilter(p?.filterByValue || ''))}`
            }
        ) {
            id
            eventName
            
            account
            marketAddress
            swapPath
            initialCollateralTokenAddress
            
            initialCollateralDeltaAmount
            sizeDeltaUsd
            triggerPrice
            acceptablePrice
            executionPrice
            minOutputAmount
            executionAmountOut

            priceImpactUsd
            priceImpactDiffUsd
            positionFeeAmount
            borrowingFeeAmount
            fundingFeeAmount
            pnlUsd

            collateralTokenPriceMax
            collateralTokenPriceMin

            indexTokenPriceMin
            indexTokenPriceMax
            
            orderType
            orderKey
            isLong
            shouldUnwrapNativeToken
            
            reason
            reasonBytes
            
            transaction {
                timestamp
                hash
            }
        }
      }`)

      const { data } = await client!.query({ query, fetchPolicy: 'no-cache' })

      return data?.tradeActions
    },
  })

  const isLoading = (!error && !data) || !marketsInfoData || !tokensData

  const tradeActions = useMemo(() => {
    if (!data || !marketsInfoData || !tokensData) {
      return undefined
    }

    const wrappedToken = getWrappedToken(chainId)

    return data
      .map((rawAction) => {
        const orderType = Number(rawAction.orderType)

        if (isSwapOrderType(orderType)) {
          const initialCollateralTokenAddress = ethers.utils.getAddress(
            rawAction.initialCollateralTokenAddress!,
          )
          const swapPath = rawAction.swapPath!.map((address) =>
            ethers.utils.getAddress(address),
          )

          const swapPathOutputAddresses = getSwapPathOutputAddresses({
            marketsInfoData,
            swapPath,
            initialCollateralAddress: initialCollateralTokenAddress,
            wrappedNativeTokenAddress: wrappedToken.address,
            shouldUnwrapNativeToken: rawAction.shouldUnwrapNativeToken!,
            isIncrease: isIncreaseOrderType(orderType),
          })

          const initialCollateralToken = getByKey(
            tokensData,
            initialCollateralTokenAddress,
          )!
          const targetCollateralToken = getByKey(
            tokensData,
            swapPathOutputAddresses.outTokenAddress,
          )!

          if (!initialCollateralToken || !targetCollateralToken) {
            return undefined
          }

          const tradeAction: SwapTradeAction = {
            id: rawAction.id,
            eventName: rawAction.eventName,
            account: rawAction.account,
            swapPath,
            orderType,
            orderKey: rawAction.orderKey,
            initialCollateralTokenAddress:
              rawAction.initialCollateralTokenAddress!,
            initialCollateralDeltaAmount: BigNumber.from(
              rawAction.initialCollateralDeltaAmount,
            ),
            minOutputAmount: BigNumber.from(rawAction.minOutputAmount),
            executionAmountOut: rawAction.executionAmountOut
              ? BigNumber.from(rawAction.executionAmountOut)
              : undefined,
            shouldUnwrapNativeToken: rawAction.shouldUnwrapNativeToken!,
            targetCollateralToken,
            initialCollateralToken,
            transaction: rawAction.transaction,
            positionFeeAmount: rawAction?.positionFeeAmount
              ? BigNumber.from(rawAction.positionFeeAmount)
              : BigNumber.from(0),
            borrowingFeeAmount: rawAction?.borrowingFeeAmount
              ? BigNumber.from(rawAction.borrowingFeeAmount)
              : BigNumber.from(0),
            fundingFeeAmount: rawAction?.fundingFeeAmount
              ? BigNumber.from(rawAction.fundingFeeAmount)
              : BigNumber.from(0),
          }

          return tradeAction
        } else {
          const marketAddress = ethers.utils.getAddress(
            rawAction.marketAddress!,
          )
          const marketInfo = getByKey(marketsInfoData, marketAddress)
          const indexToken = marketInfo?.indexToken
          const initialCollateralTokenAddress = ethers.utils.getAddress(
            rawAction.initialCollateralTokenAddress!,
          )
          const swapPath = rawAction.swapPath!.map((address) =>
            ethers.utils.getAddress(address),
          )
          const swapPathOutputAddresses = getSwapPathOutputAddresses({
            marketsInfoData,
            swapPath,
            initialCollateralAddress: initialCollateralTokenAddress,
            wrappedNativeTokenAddress: wrappedToken.address,
            shouldUnwrapNativeToken: rawAction.shouldUnwrapNativeToken!,
            isIncrease: isIncreaseOrderType(rawAction.orderType),
          })
          const initialCollateralToken = getByKey(
            tokensData,
            initialCollateralTokenAddress,
          )
          const targetCollateralToken = getByKey(
            tokensData,
            swapPathOutputAddresses.outTokenAddress,
          )

          if (
            !marketInfo ||
            !indexToken ||
            !initialCollateralToken ||
            !targetCollateralToken
          ) {
            return undefined
          }

          const sizeDeltaUsd = BigNumber.from(rawAction.sizeDeltaUsd || '0')

          let qAS = '0'
          if (rawAction.sizeDeltaUsd) {
            const p = BigNumber.from(
              rawAction.executionPrice ||
                rawAction.triggerPrice ||
                rawAction.acceptablePrice ||
                '0',
            )
            if (p.eq('0')) {
              return
            }

            qAS = BigNumber.from(rawAction.sizeDeltaUsd).div(p).toString()
          }

          const quoteAssetSize = BigNumber.from(qAS)
          const tradeAction: PositionTradeAction = {
            quoteAssetSize,
            id: rawAction.id,
            eventName: rawAction.eventName,
            account: rawAction.account,
            marketAddress,
            marketInfo,
            indexToken,
            swapPath,
            initialCollateralTokenAddress,
            initialCollateralToken,
            targetCollateralToken,
            initialCollateralDeltaAmount: BigNumber.from(
              rawAction.initialCollateralDeltaAmount,
            ),
            sizeDeltaUsd: sizeDeltaUsd,
            triggerPrice: rawAction.triggerPrice
              ? parseContractPrice(
                  BigNumber.from(rawAction.triggerPrice),
                  indexToken.decimals,
                )
              : undefined,
            acceptablePrice: parseContractPrice(
              BigNumber.from(rawAction.acceptablePrice),
              indexToken.decimals,
            ),
            executionPrice: rawAction.executionPrice
              ? parseContractPrice(
                  BigNumber.from(rawAction.executionPrice),
                  indexToken.decimals,
                )
              : undefined,
            minOutputAmount: BigNumber.from(rawAction.minOutputAmount),

            collateralTokenPriceMax: rawAction.collateralTokenPriceMax
              ? parseContractPrice(
                  BigNumber.from(rawAction.collateralTokenPriceMax),
                  initialCollateralToken.decimals,
                )
              : undefined,

            collateralTokenPriceMin: rawAction.collateralTokenPriceMin
              ? parseContractPrice(
                  BigNumber.from(rawAction.collateralTokenPriceMin),
                  initialCollateralToken.decimals,
                )
              : undefined,

            indexTokenPriceMin: rawAction.indexTokenPriceMin
              ? parseContractPrice(
                  BigNumber.from(rawAction.indexTokenPriceMin),
                  indexToken.decimals,
                )
              : undefined,
            indexTokenPriceMax: rawAction.indexTokenPriceMax
              ? parseContractPrice(
                  BigNumber.from(rawAction.indexTokenPriceMax),
                  indexToken.decimals,
                )
              : undefined,

            orderType,
            orderKey: rawAction.orderKey,
            isLong: rawAction.isLong!,
            pnlUsd: rawAction.pnlUsd
              ? BigNumber.from(rawAction.pnlUsd)
              : undefined,

            priceImpactDiffUsd: rawAction.priceImpactDiffUsd
              ? BigNumber.from(rawAction.priceImpactDiffUsd)
              : undefined,
            priceImpactUsd: rawAction.priceImpactUsd
              ? BigNumber.from(rawAction.priceImpactUsd)
              : undefined,
            positionFeeAmount: rawAction.positionFeeAmount
              ? BigNumber.from(rawAction.positionFeeAmount)
              : BigNumber.from(0),
            borrowingFeeAmount: rawAction.borrowingFeeAmount
              ? BigNumber.from(rawAction.borrowingFeeAmount)
              : BigNumber.from(0),
            fundingFeeAmount: rawAction.fundingFeeAmount
              ? BigNumber.from(rawAction.fundingFeeAmount)
              : BigNumber.from(0),

            reason: rawAction.reason,
            reasonBytes: rawAction.reasonBytes,

            transaction: rawAction.transaction,
          }

          return tradeAction
        }
      })
      .filter(Boolean) as TradeAction[]
  }, [chainId, data, marketsInfoData, tokensData])

  return {
    tradeActions,
    isLoading,
  }
}
