import {
  getMarketFullName,
  getMarketIndexName,
  getMarketPoolName,
} from 'domain/synthetics/markets'
import { OrderType, isIncreaseOrderType } from 'domain/synthetics/orders'
import { convertToUsd } from 'domain/synthetics/tokens/utils'
import { getShouldUseMaxPrice } from 'domain/synthetics/trade'
import {
  PositionTradeAction,
  TradeActionType,
} from 'domain/synthetics/tradeHistory'
import { BigNumber, ethers, constants as ethersConstants } from 'ethers'
import { PRECISION } from 'gmx/lib/legacy'
import {
  applyFactor,
  formatDeltaUsd,
  formatTokenAmount,
  formatTokenAmountWithUsd,
  formatUsd,
} from 'gmx/lib/numbers'

import { parseContractPrice } from 'domain/synthetics/tokens/useTokenRecentPricesData'
import { actionTextMap, getActionTitle } from '../keys'
import {
  INEQUALITY_GT,
  INEQUALITY_LT,
  MakeOptional,
  RowDetails,
  formatTradeActionTimestamp,
  formatTradeActionTimestampISO,
  getErrorTooltipTitle,
  infoRow,
  lines,
  numberToState,
  tryGetError,
} from './shared'

export const BN_ONE = BigNumber.from(1)
export const BN_NEGATIVE_ONE = BigNumber.from(-1)

const intl = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

export const formatPositionMessage = (
  tradeAction: PositionTradeAction,
  minCollateralUsd: BigNumber,
  relativeTimestamp = true,
): RowDetails => {
  const collateralToken = tradeAction.initialCollateralToken
  const sizeDeltaUsd = tradeAction.sizeDeltaUsd
  const collateralDeltaAmount = tradeAction.initialCollateralDeltaAmount
  const priceDecimals = tradeAction.indexToken.priceDecimals

  const ot = tradeAction.orderType
  const ev = tradeAction.eventName

  const isIncrease = isIncreaseOrderType(tradeAction.orderType)
  const isLong = tradeAction.isLong
  const longShortText = isLong ? `Long` : `Short`

  //          | long | short
  // increase |  <   |  >
  // decrease |  >   |  <
  let acceptablePriceInequality: string
  if (isIncrease && isLong) {
    acceptablePriceInequality = INEQUALITY_LT
  } else if (isIncrease && !isLong) {
    acceptablePriceInequality = INEQUALITY_GT
  } else if (!isIncrease && isLong) {
    acceptablePriceInequality = INEQUALITY_GT
  } else {
    acceptablePriceInequality = INEQUALITY_LT
  }

  //         | long | short
  // limit   |  <   |  >
  // tp      |  >   |  <
  // sl      |  <   |  >
  let triggerPriceInequality = ''
  if (ot === OrderType.LimitIncrease && isLong) {
    triggerPriceInequality = INEQUALITY_LT
  } else if (ot === OrderType.LimitIncrease && !isLong) {
    triggerPriceInequality = INEQUALITY_GT
  } else if (ot === OrderType.LimitDecrease && isLong) {
    triggerPriceInequality = INEQUALITY_GT
  } else if (ot === OrderType.LimitDecrease && !isLong) {
    triggerPriceInequality = INEQUALITY_LT
  } else if (ot === OrderType.StopLossDecrease && isLong) {
    triggerPriceInequality = INEQUALITY_LT
  } else if (ot === OrderType.StopLossDecrease && !isLong) {
    triggerPriceInequality = INEQUALITY_GT
  }

  const valueDeltaText = formatUsd(
    sizeDeltaUsd.mul(isIncrease ? BN_ONE : BN_NEGATIVE_ONE),
    {
      displayDecimals: priceDecimals,
      // displayPlus: true,
    },
  )!

  const indexName = getMarketIndexName({
    indexToken: tradeAction.indexToken,
    isSpotOnly: tradeAction.marketInfo.isSpotOnly,
  })
  const poolName = getMarketPoolName({
    longToken: tradeAction.marketInfo.longToken,
    shortToken: tradeAction.marketInfo.shortToken,
  })

  const fullMarket = getMarketFullName({
    indexToken: tradeAction.indexToken,
    longToken: tradeAction.marketInfo.longToken,
    shortToken: tradeAction.marketInfo.shortToken,
    isSpotOnly: tradeAction.marketInfo.isSpotOnly,
  })

  const marketPrice = getTokenPriceByTradeAction(tradeAction)
  const formattedMarketPrice = formatUsd(marketPrice, {
    displayDecimals: priceDecimals,
  })

  const formattedAcceptablePrice = formatUsd(tradeAction.acceptablePrice, {
    displayDecimals: priceDecimals,
  })!
  const formattedTriggerPrice = formatUsd(tradeAction.triggerPrice, {
    displayDecimals: priceDecimals,
  })!

  const action = getActionTitle(tradeAction.orderType, tradeAction.eventName)
  const timestamp = formatTradeActionTimestamp(
    tradeAction.transaction.timestamp,
    relativeTimestamp,
  )
  const timestampISO = formatTradeActionTimestampISO(
    tradeAction.transaction.timestamp,
  )

  const market = `${longShortText} ${indexName}`

  const formattedCollateralDelta = formatTokenAmount(
    collateralDeltaAmount,
    collateralToken.decimals,
    collateralToken.symbol,
    {
      useCommas: true,
      displayDecimals: priceDecimals,
    },
  )

  const formattedExecutionPrice = formatUsd(tradeAction.executionPrice, {
    displayDecimals: priceDecimals,
  })
  const formattedPriceImpact = formatDeltaUsd(tradeAction.priceImpactUsd)

  let result: MakeOptional<
    RowDetails,
    'action' | 'market' | 'timestamp' | 'timestampISO' | 'price' | 'value'
  >

  //#region MarketIncrease
  if (ot === OrderType.MarketIncrease && ev === TradeActionType.OrderCreated) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Deposit-OrderCreated']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta
    const customPrice = acceptablePriceInequality + formattedAcceptablePrice
    const priceComment = lines(`Acceptable price for the order.`)

    result = {
      action: customAction,
      value: customValue,
      price: customPrice,
      priceComment,
      acceptablePrice: customPrice,
    }
  } else if (
    ot === OrderType.MarketIncrease &&
    ev === TradeActionType.OrderExecuted
  ) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Deposit-OrderExecuted']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta
    const priceComment = sizeDeltaUsd.gt(0)
      ? lines(
          `Mark price for the order.`,
          '',
          infoRow(
            `Order Acceptable Price`,
            acceptablePriceInequality + formattedAcceptablePrice,
          ),
          infoRow(`Order Execution Price`, formattedExecutionPrice!),
          infoRow(`Price Impact`, {
            text: formattedPriceImpact!,
            state: numberToState(tradeAction.priceImpactUsd!),
          }),
          '',
          `Order execution price takes into account price impact.`,
        )
      : lines(`Mark price for the order.`)

    result = {
      action: customAction,
      value: customValue,
      priceComment: priceComment,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.MarketIncrease &&
    ev === TradeActionType.OrderCancelled
  ) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Deposit-OrderCancelled']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta
    const customPrice = acceptablePriceInequality + formattedAcceptablePrice
    const error =
      tradeAction.reasonBytes && tryGetError(tradeAction.reasonBytes)
    const priceComment = lines(
      `Acceptable price for the order.`,
      error?.args?.price && '',
      error?.args?.price &&
        infoRow(
          `Order Execution Price`,
          formatUsd(
            parseContractPrice(
              error.args.price,
              tradeAction.indexToken.decimals,
            ),
            {
              displayDecimals: priceDecimals,
            },
          ),
        ),
    )

    result = {
      action: customAction,
      actionComment:
        error &&
        lines({
          text: getErrorTooltipTitle(error.name, true),
          state: 'error',
        }),
      value: customValue,
      price: customPrice,
      priceComment,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
      isActionError: true,
    }
    //#endregion MarketIncrease
    //#region LimitIncrease
  } else if (
    (ot === OrderType.LimitIncrease && ev === TradeActionType.OrderCreated) ||
    (ot === OrderType.LimitIncrease && ev === TradeActionType.OrderUpdated) ||
    (ot === OrderType.LimitIncrease && ev === TradeActionType.OrderCancelled)
  ) {
    const customPrice =
      triggerPriceInequality +
      formatUsd(tradeAction.triggerPrice, {
        displayDecimals: priceDecimals,
      })!

    result = {
      price: customPrice,
      priceComment: lines(
        `Trigger price for the order.`,
        '',
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
      ),
      triggerPrice: customPrice,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.LimitIncrease &&
    ev === TradeActionType.OrderExecuted
  ) {
    result = {
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
        infoRow(`Order Execution Price`, formattedExecutionPrice!),
        infoRow(`Price Impact`, {
          text: formattedPriceImpact!,
          state: numberToState(tradeAction.priceImpactUsd!),
        }),
        '',
        `Order execution price takes into account price impact.`,
      ),
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.LimitIncrease &&
    ev === TradeActionType.OrderFrozen
  ) {
    let error = tradeAction.reasonBytes && tryGetError(tradeAction.reasonBytes)

    result = {
      actionComment:
        error &&
        lines({
          text: getErrorTooltipTitle(error.name, false),
          state: 'error',
        }),
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
        error?.args?.price &&
          infoRow(
            `Order Execution Price`,
            formatUsd(
              parseContractPrice(
                error.args.price,
                tradeAction.indexToken.decimals,
              ),
              {
                displayDecimals: priceDecimals,
              },
            ),
          ),
      ),
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
      isActionError: true,
    }
    //#endregion LimitIncrease
    //#region MarketDecrease
  } else if (
    ot === OrderType.MarketDecrease &&
    ev === TradeActionType.OrderCreated
  ) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Withdraw-OrderCreated']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta
    const customPrice = acceptablePriceInequality + formattedAcceptablePrice
    const priceComment = lines(`Acceptable price for the order.`)

    result = {
      action: customAction,
      value: customValue,
      price: customPrice,
      priceComment,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.MarketDecrease &&
    ev === TradeActionType.OrderCancelled
  ) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Withdraw-OrderCreated']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta
    const customPrice = acceptablePriceInequality + formattedAcceptablePrice
    const error =
      tradeAction.reasonBytes && tryGetError(tradeAction.reasonBytes)
    const priceComment = lines(
      `Acceptable price for the order.`,
      error?.args?.price && '',
      error?.args?.price &&
        infoRow(
          `Order Execution Price`,
          formatUsd(
            parseContractPrice(
              error.args.price,
              tradeAction.indexToken.decimals,
            ),
            {
              displayDecimals: priceDecimals,
            },
          ),
        ),
    )

    result = {
      action: customAction,
      actionComment:
        error &&
        lines({
          text: getErrorTooltipTitle(error.name, true),
          state: 'error',
        }),
      value: customValue,
      price: customPrice,
      priceComment,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
      isActionError: true,
    }
  } else if (
    ot === OrderType.MarketDecrease &&
    ev === TradeActionType.OrderExecuted
  ) {
    const customAction = sizeDeltaUsd.gt(0)
      ? action
      : actionTextMap['Withdraw-OrderExecuted']
    const customValue = sizeDeltaUsd.gt(0)
      ? valueDeltaText
      : formattedCollateralDelta

    result = {
      action: customAction,
      value: customValue,
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
        infoRow(`Order Execution Price`, formattedExecutionPrice!),
        infoRow(`Price Impact`, {
          text: formattedPriceImpact!,
          state: numberToState(tradeAction.priceImpactUsd!),
        }),
        '',
        `Order execution price takes into account price impact.`,
      ),
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
    //#endregion MarketDecrease
    //#region LimitDecrease
  } else if (
    (ot === OrderType.LimitDecrease && ev === TradeActionType.OrderCreated) ||
    (ot === OrderType.LimitDecrease && ev === TradeActionType.OrderUpdated) ||
    (ot === OrderType.LimitDecrease && ev === TradeActionType.OrderCancelled)
  ) {
    const customPrice =
      triggerPriceInequality +
      formatUsd(tradeAction.triggerPrice, {
        displayDecimals: priceDecimals,
      })!

    result = {
      price: customPrice,
      priceComment: lines(
        `Trigger price for the order.`,
        '',
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
      ),
      triggerPrice: customPrice,
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.LimitDecrease &&
    ev === TradeActionType.OrderExecuted
  ) {
    result = {
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
        infoRow(`Order Execution Price`, formattedExecutionPrice!),
        infoRow(`Price Impact`, {
          text: formattedPriceImpact!,
          state: numberToState(tradeAction.priceImpactUsd!),
        }),
        '',
        `Order execution price takes into account price impact.`,
      ),
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
    }
  } else if (
    ot === OrderType.LimitDecrease &&
    ev === TradeActionType.OrderFrozen
  ) {
    let error = tradeAction.reasonBytes && tryGetError(tradeAction.reasonBytes)

    result = {
      actionComment:
        error &&
        lines({
          text: getErrorTooltipTitle(error.name, false),
          state: 'error',
        }),
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        infoRow(
          `Order Acceptable Price`,
          acceptablePriceInequality + formattedAcceptablePrice,
        ),
        error?.args?.price &&
          infoRow(
            `Order Execution Price`,
            formatUsd(
              parseContractPrice(
                error.args.price,
                tradeAction.indexToken.decimals,
              ),
              {
                displayDecimals: priceDecimals,
              },
            ),
          ),
      ),
      acceptablePrice: acceptablePriceInequality + formattedAcceptablePrice,
      isActionError: true,
    }
    //#endregion LimitDecrease
    //#region StopLossDecrease
  } else if (
    (ot === OrderType.StopLossDecrease &&
      ev === TradeActionType.OrderCreated) ||
    (ot === OrderType.StopLossDecrease &&
      ev === TradeActionType.OrderUpdated) ||
    (ot === OrderType.StopLossDecrease && ev === TradeActionType.OrderCancelled)
  ) {
    const customPrice =
      triggerPriceInequality +
      formatUsd(tradeAction.triggerPrice, { displayDecimals: priceDecimals })!

    const isAcceptablePriceUseful =
      !tradeAction.acceptablePrice.isZero() &&
      !tradeAction.acceptablePrice.gte(ethersConstants.MaxInt256)

    const priceComment = isAcceptablePriceUseful
      ? lines(
          `Trigger price for the order.`,
          '',
          infoRow(
            `Order Acceptable Price`,
            acceptablePriceInequality + formattedAcceptablePrice,
          ),
        )
      : lines(`Trigger price for the order.`)

    result = {
      price: customPrice,
      priceComment: priceComment,
      triggerPrice: customPrice,
    }
  } else if (
    ot === OrderType.StopLossDecrease &&
    ev === TradeActionType.OrderExecuted
  ) {
    const isAcceptablePriceUseful =
      !tradeAction.acceptablePrice.isZero() &&
      !tradeAction.acceptablePrice.gte(ethersConstants.MaxInt256)

    result = {
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        isAcceptablePriceUseful
          ? infoRow(
              `Order Acceptable Price`,
              acceptablePriceInequality + formattedAcceptablePrice,
            )
          : undefined,
        infoRow(`Order Execution Price`, formattedExecutionPrice!),
        infoRow(`Price Impact`, {
          text: formattedPriceImpact!,
          state: numberToState(tradeAction.priceImpactUsd!),
        }),
        '',
        `Order execution price takes into account price impact.`,
      ),
    }
  } else if (
    ot === OrderType.StopLossDecrease &&
    ev === TradeActionType.OrderFrozen
  ) {
    let error = tradeAction.reasonBytes && tryGetError(tradeAction.reasonBytes)
    const isAcceptablePriceUseful =
      !tradeAction.acceptablePrice.isZero() &&
      !tradeAction.acceptablePrice.gte(ethersConstants.MaxInt256)

    result = {
      actionComment:
        error &&
        lines({
          text: getErrorTooltipTitle(error.name, false),
          state: 'error',
        }),
      priceComment: lines(
        `Mark price for the order.`,
        '',
        infoRow(
          `Order Trigger Price`,
          triggerPriceInequality + formattedTriggerPrice,
        ),
        isAcceptablePriceUseful
          ? infoRow(
              `Order Acceptable Price`,
              acceptablePriceInequality + formattedAcceptablePrice,
            )
          : undefined,
        error?.args?.price &&
          infoRow(
            `Order Execution Price`,
            formatUsd(
              parseContractPrice(
                error.args.price,
                tradeAction.indexToken.decimals,
              ),
              {
                displayDecimals: priceDecimals,
              },
            ),
          ),
      ),
      isActionError: true,
    }

    //#endregion StopLossDecrease
    //#region Liquidation
  } else if (
    ot === OrderType.Liquidation &&
    ev === TradeActionType.OrderExecuted
  ) {
    const maxLeverage = tradeAction.marketInfo.minCollateralFactor.gt(0)
      ? PRECISION?.div(tradeAction.marketInfo.minCollateralFactor)
      : BigNumber.from(0)
    const formattedMaxLeverage = Number(maxLeverage).toFixed(1) + 'x'

    const initialCollateralUsd = convertToUsd(
      tradeAction.initialCollateralDeltaAmount,
      tradeAction.initialCollateralToken?.decimals,
      tradeAction.collateralTokenPriceMin,
    )

    const formattedInitialCollateral = formatTokenAmountWithUsd(
      tradeAction.initialCollateralDeltaAmount,
      initialCollateralUsd,
      tradeAction.initialCollateralToken?.symbol,
      tradeAction.initialCollateralToken?.decimals,
      {
        displayDecimals: priceDecimals,
      },
    )

    const formattedPnl = formatUsd(tradeAction.pnlUsd)!
    const formattedBasePnl = formatUsd(tradeAction.basePnlUsd)!

    const borrowingFeeUsd = convertToUsd(
      tradeAction.borrowingFeeAmount,
      tradeAction.initialCollateralToken?.decimals,
      tradeAction.collateralTokenPriceMin,
    )
    const formattedBorrowFee = formatUsd(borrowingFeeUsd?.mul(-1))!

    const fundingFeeUsd = convertToUsd(
      tradeAction.fundingFeeAmount,
      tradeAction.initialCollateralToken?.decimals,
      tradeAction.collateralTokenPriceMin,
    )
    const formattedFundingFee = formatUsd(fundingFeeUsd?.mul(-1))!

    const positionFeeUsd = convertToUsd(
      tradeAction.positionFeeAmount,
      tradeAction.initialCollateralToken?.decimals,
      tradeAction.collateralTokenPriceMin,
    )
    const formattedPositionFee = formatUsd(positionFeeUsd?.mul(-1))!

    let liquidationCollateralUsd = applyFactor(
      sizeDeltaUsd,
      tradeAction.marketInfo.minCollateralFactor,
    )
    if (liquidationCollateralUsd.lt(minCollateralUsd)) {
      liquidationCollateralUsd = minCollateralUsd
    }

    let leftoverCollateralUsd = initialCollateralUsd
      ? initialCollateralUsd
          ?.add(tradeAction.basePnlUsd! || BigNumber.from(0))
          .sub(borrowingFeeUsd!)
          .sub(fundingFeeUsd!)
          .sub(positionFeeUsd!)
      : BigNumber.from(0)

    const formattedLeftoverCollateral = formatUsd(leftoverCollateralUsd!)
    const formattedMinCollateral = formatUsd(liquidationCollateralUsd)!

    result = {
      priceComment: lines(
        `Mark price for the liquidation.`,
        '',
        `This position was liquidated as the max. leverage of ${formattedMaxLeverage} was exceeded when taking into account fees.`,
        '',
        infoRow(`Order Execution Price`, formattedExecutionPrice!),
        '',
        `Order execution price takes into account price impact.`,
        '',
        infoRow(`Initial Collateral`, formattedInitialCollateral!),
        infoRow(`PnL`, {
          text: formattedBasePnl,
          state: numberToState(tradeAction.basePnlUsd!),
        }),
        infoRow(`Borrow Fee`, {
          text: formattedBorrowFee,
          state: 'error',
        }),
        infoRow(`Funding Fee`, {
          text: formattedFundingFee,
          state: 'error',
        }),
        infoRow(`Position Fee`, {
          text: formattedPositionFee,
          state: 'error',
        }),
        infoRow(`Price Impact`, {
          text: formattedPriceImpact!,
          state: numberToState(tradeAction.priceImpactUsd!),
        }),
        '',
        infoRow(`PnL after Fees and Price Impact`, {
          text: formattedPnl,
          state: numberToState(tradeAction.pnlUsd!),
        }),
        '',
        infoRow(
          `Leftover Collateral Excluding Impact`,
          formattedLeftoverCollateral,
        ),
        infoRow(`Min. required Collateral`, formattedMinCollateral),
      ),
      isActionError: true,
    }
    //#endregion Liquidation
  }

  let size = ''
  if (tradeAction.quoteAssetSize?.gt('0')) {
    const threshold = ethers.utils.parseEther('0.001')
    if (tradeAction.quoteAssetSize.lt(threshold)) {
      size = '< 0.001'
    } else {
      size = ethers.utils.formatEther(tradeAction.quoteAssetSize)
      size = intl.format(+size)
    }
  }

  return {
    action,
    market,
    fullMarket,
    timestamp,
    timestampISO,
    price: formattedMarketPrice || '',
    value: valueDeltaText,
    marketPrice: formattedMarketPrice,
    executionPrice: formattedExecutionPrice,
    priceImpact: formattedPriceImpact,
    indexName,
    indexToken: tradeAction.indexToken,
    poolName,
    ...result!,
    longShortText: longShortText,
    size,
  }
}

function getTokenPriceByTradeAction(tradeAction: PositionTradeAction) {
  return getShouldUseMaxPrice(
    isIncreaseOrderType(tradeAction.orderType),
    tradeAction.isLong,
  )
    ? tradeAction.indexTokenPriceMax
    : tradeAction.indexTokenPriceMin
}
