import { useClaimCollateralHistory } from 'domain/synthetics/claimHistory'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { TokensData } from 'domain/synthetics/tokens'
import { useChainId } from 'gmx/lib/chains'
import useWallet from 'gmx/lib/wallets/useWallet'
import { useCallback, useState } from 'react'
import { ClaimableCard } from './ClaimableCard'

import RfxLoader from '@components/shared/RfxLoader'
import { ClaimHistoryRow } from 'components/Synthetics/ClaimHistoryRow/ClaimHistoryRow'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { SettleAccruedCard } from './SettleAccruedCard'

const PAGE_SIZE = 100

type Props = {
  shouldShowPaginationButtons: boolean
  marketsInfoData: MarketsInfoData | undefined
  tokensData: TokensData | undefined
  setIsClaiming?: (isClaiming: boolean) => void
  setIsSettling?: (isSettling: boolean) => void
  positionsInfoData: PositionsInfoData | undefined
}

export function Claims({
  shouldShowPaginationButtons,
  marketsInfoData,
  tokensData,
  setIsClaiming,
  setIsSettling,
  positionsInfoData,
}: Props) {
  const { chainId } = useChainId()
  const { account } = useWallet()
  const [pageIndex, setPageIndex] = useState(0)

  const { claimActions, isLoading } = useClaimCollateralHistory(chainId, {
    marketsInfoData,
    tokensData,
    pageIndex,
    pageSize: PAGE_SIZE,
  })

  const handleClaimClick = useCallback(() => {
    if (!setIsClaiming) {
      return
    }
    setIsClaiming(true)
  }, [setIsClaiming])

  const handleSettleClick = useCallback(() => {
    if (!setIsSettling) {
      return
    }
    setIsSettling(true)
  }, [setIsSettling])

  if (account && isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <RfxLoader />
      </div>
    )
  }

  return (
    <div className="h-full">
      {setIsClaiming && setIsSettling && account && !isLoading && (
        <div>
          <SettleAccruedCard
            positionsInfoData={positionsInfoData}
            onSettleClick={handleSettleClick}
          />
          <ClaimableCard
            marketsInfoData={marketsInfoData}
            onClaimClick={handleClaimClick}
          />
        </div>
      )}

      {!!(!isLoading && claimActions?.length) && (
        <>
          {!!(setIsClaiming && setIsSettling) && (
            <div className="flex h-8 items-center border-th-input-border bg-th-bkg-3 px-4 text-base font-semibold text-th-fgd-1 sm:h-12 sm:border-b sm:px-6 sm:text-sm">
              Claim History
            </div>
          )}
          <table className="h-full w-full">
            <thead className="table w-full table-fixed text-left">
              <tr className="h-10 border-b border-th-input-border uppercase">
                <th className="pl-6 text-xs font-normal text-th-fgd-2">
                  Action
                </th>
                <th className="text-xs font-normal text-th-fgd-2">Market</th>
                <th className="text-xs font-normal text-th-fgd-2">Size</th>
              </tr>
            </thead>

            <tbody className="block h-[calc(100%-40px)] overflow-y-scroll">
              {claimActions?.map((claimAction) => (
                <ClaimHistoryRow
                  key={claimAction.id}
                  claimAction={claimAction}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
      {shouldShowPaginationButtons && (
        <div>
          {pageIndex > 0 && (
            <button
              className="App-button-option App-card-option"
              onClick={() => setPageIndex((old) => old - 1)}
            >
              Prev
            </button>
          )}
          {!!(claimActions && claimActions.length >= PAGE_SIZE) && (
            <button
              className="App-button-option App-card-option"
              onClick={() => setPageIndex((old) => old + 1)}
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  )
}
