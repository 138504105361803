import EmptyState from '@components/shared/EmptyState'
import RfxLoader from '@components/shared/RfxLoader'
import { SortableColumnHeader } from '@components/shared/TableElements'
import {
  useSubaccount,
  useSubaccountCancelOrdersDetailsMessage,
} from 'context/SubaccountContext/SubaccountContext'
import { MarketsInfoData } from 'domain/synthetics/markets'
import {
  OrdersInfoData,
  isLimitOrderType,
  isTriggerDecreaseOrderType,
} from 'domain/synthetics/orders'
import { cancelOrdersTxn } from 'domain/synthetics/orders/cancelOrdersTxn'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { TokensData } from 'domain/synthetics/tokens'
import { useChainId } from 'gmx/lib/chains'
import useWallet from 'gmx/lib/wallets/useWallet'
import { PendingTransaction, usePaymaster } from 'hooks/usePaymaster'
import { useSortableData } from 'hooks/useSortableData'
import { useViewport } from 'hooks/useViewport'
import { Dispatch, SetStateAction, useState } from 'react'
import { OrderEditor } from '../OrderEditor/OrderEditor'
import { OrderItem } from '../OrderItem/OrderItem'

type Props = {
  hideActions?: boolean
  ordersData?: OrdersInfoData
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  positionsData?: PositionsInfoData
  setSelectedOrdersKeys?: Dispatch<SetStateAction<{ [key: string]: boolean }>>
  selectedOrdersKeys?: { [key: string]: boolean }
  isLoading: boolean
  setPendingTxns: (txns: PendingTransaction[]) => void
  isSwap?: boolean
}

export function OrderList(p: Props) {
  const { isMobile } = useViewport()
  const { marketsInfoData, tokensData, positionsData } = p
  const { chainId } = useChainId()
  const { signer, account } = useWallet()
  const subaccount = useSubaccount(null)
  const cancelOrdersDetailsMessage = useSubaccountCancelOrdersDetailsMessage(
    undefined,
    1,
  )

  const { sendPaymasterTransaction } = usePaymaster()

  const [canellingOrdersKeys, setCanellingOrdersKeys] = useState<string[]>([])
  const [editingOrderKey, setEditingOrderKey] = useState<string>()

  const orders = Object.values(p.ordersData || {}).filter(
    (order) =>
      isLimitOrderType(order.orderType) ||
      isTriggerDecreaseOrderType(order.orderType),
  )

  const editingOrder =
    !!editingOrderKey && orders.find((o) => o.key === editingOrderKey)

  function onSelectOrder(key: string) {
    p.setSelectedOrdersKeys?.((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  function onCancelOrder(key: string) {
    if (!signer) {
      return
    }
    setCanellingOrdersKeys((prev) => [...prev, key])

    cancelOrdersTxn(chainId, signer, subaccount, sendPaymasterTransaction, {
      orderKeys: [key],
      setPendingTxns: p.setPendingTxns,
      account,
      detailsMsg: cancelOrdersDetailsMessage,
    }).finally(() =>
      setCanellingOrdersKeys((prev) => prev.filter((k) => k !== key)),
    )
  }

  const { items: tableData, requestSort, sortConfig } = useSortableData(orders)

  if (orders.length === 0) {
    return (
      <div className="flex h-full flex-1 max-sm:min-h-[30vh]">
        {p.isLoading ? <RfxLoader /> : <EmptyState text="No open orders" />}
      </div>
    )
  }

  return (
    <div className="h-full">
      <table className="h-full w-full border-collapse text-left">
        {!isMobile && (
          <thead className="table w-full table-fixed">
            <tr className="h-10 border-b border-th-input-border">
              <th>
                <SortableColumnHeader
                  sortKey="indexToken.symbol"
                  sort={() => requestSort('indexToken.symbol')}
                  sortConfig={sortConfig}
                  title={'Market'}
                  titleClass="text-xs font-normal text-th-fgd-2 pl-6"
                />
              </th>
              <th>
                <SortableColumnHeader
                  sortKey="orderType"
                  sort={() => requestSort('orderType')}
                  sortConfig={sortConfig}
                  title={'Order Type'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th>
                <SortableColumnHeader
                  sortKey="isLong"
                  sort={() => requestSort('isLong')}
                  sortConfig={sortConfig}
                  title={'L/S'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th align="left">
                <SortableColumnHeader
                  sortKey="sizeDeltaUsd"
                  sort={() => requestSort('sizeDeltaUsd')}
                  sortConfig={sortConfig}
                  title={'Size'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th align="left">
                <SortableColumnHeader
                  sortKey="triggerPrice"
                  sort={() => requestSort('triggerPrice')}
                  sortConfig={sortConfig}
                  title={'Price'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th align="left">
                <SortableColumnHeader
                  sortKey="orderType"
                  sort={() => requestSort('orderType')}
                  sortConfig={sortConfig}
                  title={'Filled'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th align="left">
                <SortableColumnHeader
                  sortKey="orderType"
                  sort={() => requestSort('orderType')}
                  sortConfig={sortConfig}
                  title={'Value'}
                  titleClass="text-xs font-normal text-th-fgd-2"
                />
              </th>
              <th className="min-w-fit max-w-[204px] pr-6"></th>
            </tr>
          </thead>
        )}

        <tbody className="h-[calc(100%-40px)] overflow-y-scroll sm:block">
          {!p.isLoading &&
            tableData.map((order) => {
              return (
                <OrderItem
                  isSelected={p.selectedOrdersKeys?.[order.key]}
                  key={order.key}
                  order={order}
                  onSelectOrder={() => onSelectOrder(order.key)}
                  isCanceling={canellingOrdersKeys.includes(order.key)}
                  onCancelOrder={() => onCancelOrder(order.key)}
                  onEditOrder={() => setEditingOrderKey(order.key)}
                  hideActions={p.hideActions}
                  marketsInfoData={marketsInfoData}
                  // positionsInfoData={positionsData}
                  isSwap={!!p.isSwap}
                />
              )
            })}
        </tbody>
      </table>

      {editingOrder && (
        <OrderEditor
          marketsInfoData={marketsInfoData}
          tokensData={tokensData}
          positionsData={positionsData}
          order={editingOrder}
          onClose={() => setEditingOrderKey(undefined)}
          setPendingTxns={p.setPendingTxns}
        />
      )}
    </div>
  )
}
