export enum CustomErrorName {
  ActionAlreadySignalled = 'ActionAlreadySignalled',
  ActionNotSignalled = 'ActionNotSignalled',
  AdlNotEnabled = 'AdlNotEnabled',
  AdlNotRequired = 'AdlNotRequired',
  ArrayOutOfBoundsBytes = 'ArrayOutOfBoundsBytes',
  ArrayOutOfBoundsUint256 = 'ArrayOutOfBoundsUint256',
  BlockNumbersNotSorted = 'BlockNumbersNotSorted',
  CollateralAlreadyClaimed = 'CollateralAlreadyClaimed',
  CompactedArrayOutOfBounds = 'CompactedArrayOutOfBounds',
  DepositNotFound = 'DepositNotFound',
  DisabledFeature = 'DisabledFeature',
  DisabledMarket = 'DisabledMarket',
  DuplicatedIndex = 'DuplicatedIndex',
  DuplicatedMarketInSwapPath = 'DuplicatedMarketInSwapPath',
  EmptyAccount = 'EmptyAccount',
  EmptyAddressInMarketTokenBalanceValidation = 'EmptyAddressInMarketTokenBalanceValidation',
  EmptyCompactedBlockNumber = 'EmptyCompactedBlockNumber',
  EmptyCompactedPrice = 'EmptyCompactedPrice',
  EmptyCompactedTimestamp = 'EmptyCompactedTimestamp',
  EmptyDeposit = 'EmptyDeposit',
  EmptyDepositAmounts = 'EmptyDepositAmounts',
  EmptyDepositAmountsAfterSwap = 'EmptyDepositAmountsAfterSwap',
  EmptyHoldingAddress = 'EmptyHoldingAddress',
  EmptyMarket = 'EmptyMarket',
  EmptyMarketTokenSupply = 'EmptyMarketTokenSupply',
  EmptyOrder = 'EmptyOrder',
  EmptyPosition = 'EmptyPosition',
  EmptyPriceFeed = 'EmptyPriceFeed',
  EmptyPriceFeedMultiplier = 'EmptyPriceFeedMultiplier',
  EmptyPrimaryPrice = 'EmptyPrimaryPrice',
  EmptyRealtimeFeedId = 'EmptyRealtimeFeedId',
  EmptyRealtimeFeedMultiplier = 'EmptyRealtimeFeedMultiplier',
  EmptyReceiver = 'EmptyReceiver',
  EmptySigner = 'EmptySigner',
  EmptySizeDeltaInTokens = 'EmptySizeDeltaInTokens',
  EmptyTokenTranferGasLimit = 'EmptyTokenTranferGasLimit',
  EmptyWithdrawal = 'EmptyWithdrawal',
  EmptyWithdrawalAmount = 'EmptyWithdrawalAmount',
  EndOfOracleSimulation = 'EndOfOracleSimulation',
  HasRealtimeFeedId = 'HasRealtimeFeedId',
  InsufficientCollateralAmount = 'InsufficientCollateralAmount',
  InsufficientCollateralUsd = 'InsufficientCollateralUsd',
  InsufficientExecutionFee = 'InsufficientExecutionFee',
  InsufficientExecutionGas = 'InsufficientExecutionGas',
  InsufficientFundsToPayForCosts = 'InsufficientFundsToPayForCosts',
  InsufficientMarketTokens = 'InsufficientMarketTokens',
  InsufficientOutputAmount = 'InsufficientOutputAmount',
  InsufficientPoolAmount = 'InsufficientPoolAmount',
  InsufficientReserve = 'InsufficientReserve',
  InsufficientReserveForOpenInterest = 'InsufficientReserveForOpenInterest',
  InsufficientSwapOutputAmount = 'InsufficientSwapOutputAmount',
  InsufficientWntAmount = 'InsufficientWntAmount',
  InsufficientWntAmountForExecutionFee = 'InsufficientWntAmountForExecutionFee',
  InvalidAdl = 'InvalidAdl',
  InvalidBaseKey = 'InvalidBaseKey',
  InvalidBlockNumber = 'InvalidBlockNumber',
  InvalidBlockRangeSet = 'InvalidBlockRangeSet',
  InvalidClaimAffiliateRewardsInput = 'InvalidClaimAffiliateRewardsInput',
  InvalidClaimCollateralInput = 'InvalidClaimCollateralInput',
  InvalidClaimFeesInput = 'InvalidClaimFeesInput',
  InvalidClaimFundingFeesInput = 'InvalidClaimFundingFeesInput',
  InvalidClaimUiFeesInput = 'InvalidClaimUiFeesInput',
  InvalidCollateralTokenForMarket = 'InvalidCollateralTokenForMarket',
  InvalidDecreaseOrderSize = 'InvalidDecreaseOrderSize',
  InvalidDecreasePositionSwapType = 'InvalidDecreasePositionSwapType',
  InvalidFeeFactor = 'InvalidFeeFactor',
  InvalidFeeReceiver = 'InvalidFeeReceiver',
  InvalidFeedPrice = 'InvalidFeedPrice',
  InvalidKeeperForFrozenOrder = 'InvalidKeeperForFrozenOrder',
  InvalidMarketTokenBalance = 'InvalidMarketTokenBalance',
  InvalidMarketTokenBalanceForClaimableFunding = 'InvalidMarketTokenBalanceForClaimableFunding',
  InvalidMarketTokenBalanceForCollateralAmount = 'InvalidMarketTokenBalanceForCollateralAmount',
  InvalidMedianMinMaxPrice = 'InvalidMedianMinMaxPrice',
  InvalidMinMaxBlockNumber = 'InvalidMinMaxBlockNumber',
  InvalidMinMaxForPrice = 'InvalidMinMaxForPrice',
  InvalidNativeTokenSender = 'InvalidNativeTokenSender',
  InvalidOraclePrice = 'InvalidOraclePrice',
  InvalidOracleSigner = 'InvalidOracleSigner',
  InvalidOrderPrices = 'InvalidOrderPrices',
  InvalidOutputToken = 'InvalidOutputToken',
  InvalidPoolValueForDeposit = 'InvalidPoolValueForDeposit',
  InvalidPoolValueForWithdrawal = 'InvalidPoolValueForWithdrawal',
  InvalidPositionMarket = 'InvalidPositionMarket',
  InvalidPositionSizeValues = 'InvalidPositionSizeValues',
  InvalidPrimaryPricesForSimulation = 'InvalidPrimaryPricesForSimulation',
  InvalidRealtimeBidAsk = 'InvalidRealtimeBidAsk',
  InvalidRealtimeBlockHash = 'InvalidRealtimeBlockHash',
  InvalidRealtimeFeedId = 'InvalidRealtimeFeedId',
  InvalidRealtimeFeedLengths = 'InvalidRealtimeFeedLengths',
  InvalidRealtimePrices = 'InvalidRealtimePrices',
  InvalidSignature = 'InvalidSignature',
  InvalidSignerMinMaxPrice = 'InvalidSignerMinMaxPrice',
  InvalidSizeDeltaForAdl = 'InvalidSizeDeltaForAdl',
  InvalidSwapMarket = 'InvalidSwapMarket',
  InvalidSwapOutputToken = 'InvalidSwapOutputToken',
  InvalidTimelockDelay = 'InvalidTimelockDelay',
  InvalidTokenIn = 'InvalidTokenIn',
  InvalidUiFeeFactor = 'InvalidUiFeeFactor',
  LiquidatablePosition = 'LiquidatablePosition',
  MarketAlreadyExists = 'MarketAlreadyExists',
  MarketNotFound = 'MarketNotFound',
  MaskIndexOutOfBounds = 'MaskIndexOutOfBounds',
  MaxCallbackGasLimitExceeded = 'MaxCallbackGasLimitExceeded',
  MaxOpenInterestExceeded = 'MaxOpenInterestExceeded',
  MaxOracleSigners = 'MaxOracleSigners',
  MaxPoolAmountExceeded = 'MaxPoolAmountExceeded',
  MaxPoolAmountForDepositExceeded = 'MaxPoolAmountForDepositExceeded',
  MaxPriceAgeExceeded = 'MaxPriceAgeExceeded',
  MaxPricesNotSorted = 'MaxPricesNotSorted',
  MaxRefPriceDeviationExceeded = 'MaxRefPriceDeviationExceeded',
  MaxSignerIndex = 'MaxSignerIndex',
  MaxSwapPathLengthExceeded = 'MaxSwapPathLengthExceeded',
  MaxTimelockDelayExceeded = 'MaxTimelockDelayExceeded',
  MinLongTokens = 'MinLongTokens',
  MinMarketTokens = 'MinMarketTokens',
  MinOracleSigners = 'MinOracleSigners',
  MinPositionSize = 'MinPositionSize',
  MinPricesNotSorted = 'MinPricesNotSorted',
  MinShortTokens = 'MinShortTokens',
  NegativeExecutionPrice = 'NegativeExecutionPrice',
  NonEmptyTokensWithPrices = 'NonEmptyTokensWithPrices',
  OpenInterestCannotBeUpdatedForSwapOnlyMarket = 'OpenInterestCannotBeUpdatedForSwapOnlyMarket',
  OracleBlockNumberNotWithinRange = 'OracleBlockNumberNotWithinRange',
  OracleBlockNumbersAreSmallerThanRequired = 'OracleBlockNumbersAreSmallerThanRequired',
  OrderAlreadyFrozen = 'OrderAlreadyFrozen',
  OrderNotFound = 'OrderNotFound',
  OrderNotFulfillableAtAcceptablePrice = 'OrderNotFulfillableAtAcceptablePrice',
  OrderNotUpdatable = 'OrderNotUpdatable',
  OrderTypeCannotBeCreated = 'OrderTypeCannotBeCreated',
  PnlFactorExceededForLongs = 'PnlFactorExceededForLongs',
  PnlFactorExceededForShorts = 'PnlFactorExceededForShorts',
  PnlOvercorrected = 'PnlOvercorrected',
  PositionNotFound = 'PositionNotFound',
  PositionShouldNotBeLiquidated = 'PositionShouldNotBeLiquidated',
  PriceAlreadySet = 'PriceAlreadySet',
  PriceFeedNotUpdated = 'PriceFeedNotUpdated',
  PriceImpactLargerThanOrderSize = 'PriceImpactLargerThanOrderSize',
  RealtimeMaxPriceAgeExceeded = 'RealtimeMaxPriceAgeExceeded',
  RequestNotYetCancellable = 'RequestNotYetCancellable',
  SelfTransferNotSupported = 'SelfTransferNotSupported',
  SignalTimeNotYetPassed = 'SignalTimeNotYetPassed',
  SwapPriceImpactExceedsAmountIn = 'SwapPriceImpactExceedsAmountIn',
  ThereMustBeAtLeastOneRoleAdmin = 'ThereMustBeAtLeastOneRoleAdmin',
  ThereMustBeAtLeastOneTimelockMultiSig = 'ThereMustBeAtLeastOneTimelockMultiSig',
  TokenTransferError = 'TokenTransferError',
  UnableToGetBorrowingFactorEmptyPoolUsd = 'UnableToGetBorrowingFactorEmptyPoolUsd',
  UnableToGetCachedTokenPrice = 'UnableToGetCachedTokenPrice',
  UnableToGetFundingFactorEmptyOpenInterest = 'UnableToGetFundingFactorEmptyOpenInterest',
  UnableToGetOppositeToken = 'UnableToGetOppositeToken',
  UnableToWithdrawCollateral = 'UnableToWithdrawCollateral',
  Unauthorized = 'Unauthorized',
  UnexpectedBorrowingFactor = 'UnexpectedBorrowingFactor',
  UnexpectedMarket = 'UnexpectedMarket',
  UnexpectedPoolValue = 'UnexpectedPoolValue',
  UnexpectedPositionState = 'UnexpectedPositionState',
  UnexpectedTokenForVirtualInventory = 'UnexpectedTokenForVirtualInventory',
  UnsupportedOracleBlockNumberType = 'UnsupportedOracleBlockNumberType',
  UnsupportedOrderType = 'UnsupportedOrderType',
  UsdDeltaExceedsLongOpenInterest = 'UsdDeltaExceedsLongOpenInterest',
  UsdDeltaExceedsPoolValue = 'UsdDeltaExceedsPoolValue',
  UsdDeltaExceedsShortOpenInterest = 'UsdDeltaExceedsShortOpenInterest',
  WithdrawalNotFound = 'WithdrawalNotFound',
}
