import cx from 'classnames'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { MarketsInfoData } from 'domain/synthetics/markets'
import {
  OrderInfo,
  OrderType,
  PositionOrderInfo,
  SwapOrderInfo,
  isDecreaseOrderType,
  isIncreaseOrderType,
  isLimitOrderType,
  isSwapOrderType,
  isTriggerDecreaseOrderType,
} from 'domain/synthetics/orders'
import {
  PositionsInfoData,
  formatAcceptablePrice,
  formatLiquidationPrice,
  getPositionKey,
  getTriggerNameByOrderType,
} from 'domain/synthetics/positions'
import {
  TokensData,
  TokensRatio,
  convertToTokenAmount,
  getAmountByRatio,
  getTokenData,
  getTokensRatioByPrice,
} from 'domain/synthetics/tokens'
import { useChainId } from 'gmx/lib/chains'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import {
  formatAmount,
  formatAmountFree,
  formatDeltaUsd,
  formatTokenAmount,
  formatUsd,
  parseValue,
} from 'gmx/lib/numbers'
import { useEffect, useMemo, useState } from 'react'

import Button from '@components/shared/Button'
import Modal from '@components/shared/Modal'
import tradeboxStore from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS } from 'config/factors'
import { SYNTHETICS_ACCEPTABLE_PRICE_IMPACT_BPS_KEY } from 'config/localStorage'
import { getWrappedToken } from 'config/tokens'
import { useSubaccount } from 'context/SubaccountContext/SubaccountContext'
import {
  estimateExecuteDecreaseOrderGasLimit,
  estimateExecuteIncreaseOrderGasLimit,
  estimateExecuteSwapOrderGasLimit,
  getExecutionFee,
  useGasLimits,
  useGasPrice,
} from 'domain/synthetics/fees'
import { estimateOrderOraclePriceCount } from 'domain/synthetics/fees/utils/estimateOraclePriceCount'
import { updateOrderTxn } from 'domain/synthetics/orders/updateOrderTxn'
import {
  applySlippageToPrice,
  getAcceptablePrice,
  getSwapPathOutputAddresses,
} from 'domain/synthetics/trade'
import { BigNumber } from 'ethers'
import { useLocalStorageSerializeKey } from 'gmx/lib/localStorage'
import { getByKey } from 'gmx/lib/objects'
import useWallet from 'gmx/lib/wallets/useWallet'
import { PendingTransaction, usePaymaster } from 'hooks/usePaymaster'
import { useViewport } from 'hooks/useViewport'
import { useAccount } from 'wagmi'

type Props = {
  positionsData?: PositionsInfoData
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  order: OrderInfo
  onClose: () => void
  setPendingTxns: (txns: PendingTransaction[]) => void
}

export function OrderEditor(p: Props) {
  const { isMobile } = useViewport()
  const { marketsInfoData, tokensData } = p
  const { sendPaymasterTransaction } = usePaymaster()
  const { chainId } = useChainId()
  const { signer } = useWallet()
  const { address } = useAccount()

  const { gasPrice } = useGasPrice(chainId)
  const { gasLimits } = useGasLimits(chainId)
  const [savedAcceptablePriceImpactBps] = useLocalStorageSerializeKey(
    [chainId, SYNTHETICS_ACCEPTABLE_PRICE_IMPACT_BPS_KEY],
    DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS,
  )
  const acceptablePriceImpactBps = BigNumber.from(
    savedAcceptablePriceImpactBps || DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS,
  )

  const allowedSlippage = tradeboxStore((store) => store.slippage)

  const [isInited, setIsInited] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [sizeInputValue, setSizeInputValue] = useState('')
  const sizeDeltaUsd = parseValue(sizeInputValue || '0', USD_DECIMALS)

  const [triggerPirceInputValue, setTriggerPriceInputValue] = useState('')
  const triggerPrice = parseValue(triggerPirceInputValue || '0', USD_DECIMALS)!

  let { acceptablePrice } = getAcceptablePrice({
    isIncrease: isIncreaseOrderType(p.order.orderType),
    isLong: p.order.isLong,
    indexPrice: triggerPrice,
    acceptablePriceImpactBps: acceptablePriceImpactBps,
    sizeDeltaUsd: p.order.sizeDeltaUsd,
  })

  // For SL orders Acceptable Price is not applicable and set to 0 or MaxUnit256
  if (p.order.orderType === OrderType.StopLossDecrease) {
    acceptablePrice = (p.order as PositionOrderInfo).acceptablePrice
  }

  // Swaps
  const fromToken = getTokenData(
    tokensData,
    p.order.initialCollateralTokenAddress,
  )

  const swapPathInfo = marketsInfoData
    ? getSwapPathOutputAddresses({
        marketsInfoData: marketsInfoData,
        initialCollateralAddress: p.order.initialCollateralTokenAddress,
        swapPath: p.order.swapPath,
        wrappedNativeTokenAddress: getWrappedToken(chainId).address,
        shouldUnwrapNativeToken: p.order.shouldUnwrapNativeToken,
        isIncrease: isIncreaseOrderType(p.order.orderType),
      })
    : undefined

  const toTokenAddress = swapPathInfo?.outTokenAddress

  const toToken = getTokenData(tokensData, toTokenAddress)
  const fromTokenPrice = fromToken?.prices?.maxPrice
  const toTokenPrice = toToken?.prices?.minPrice
  const [triggerRatioInputValue, setTriggerRatioInputValue] =
    useState<string>('')

  const markRatio =
    fromToken &&
    toToken &&
    fromTokenPrice &&
    toTokenPrice &&
    getTokensRatioByPrice({
      fromToken,
      toToken,
      fromPrice: fromTokenPrice,
      toPrice: toTokenPrice,
    })

  const isRatioInverted = markRatio?.largestToken.address === fromToken?.address

  const triggerRatio = useMemo(() => {
    if (!markRatio || !isSwapOrderType(p.order.orderType)) {
      return undefined
    }

    const ratio = parseValue(triggerRatioInputValue, USD_DECIMALS)

    return {
      ratio: ratio?.gt(0) ? ratio : markRatio.ratio,
      largestToken: markRatio.largestToken,
      smallestToken: markRatio.smallestToken,
    } as TokensRatio
  }, [markRatio, p.order.orderType, triggerRatioInputValue])

  let minOutputAmount = p.order.minOutputAmount

  if (fromToken && toToken && triggerRatio) {
    minOutputAmount = getAmountByRatio({
      fromToken,
      toToken,
      fromTokenAmount: p.order.initialCollateralDeltaAmount,
      ratio: triggerRatio?.ratio,
      shouldInvertRatio: !isRatioInverted,
    })

    const priceImpactAmount = convertToTokenAmount(
      p.order.swapPathStats?.totalSwapPriceImpactDeltaUsd,
      p.order.targetCollateralToken.decimals,
      p.order.targetCollateralToken.prices.minPrice,
    )

    const swapFeeAmount = convertToTokenAmount(
      p.order.swapPathStats?.totalSwapFeeUsd,
      p.order.targetCollateralToken.decimals,
      p.order.targetCollateralToken.prices.minPrice,
    )

    minOutputAmount = minOutputAmount
      .add(priceImpactAmount || 0)
      .sub(swapFeeAmount || 0)
  }

  const market = getByKey(marketsInfoData, p.order.marketAddress)
  const indexToken = getTokenData(tokensData, market?.indexTokenAddress)
  const indexPriceDecimals = indexToken?.priceDecimals
  const markPrice = p.order.isLong
    ? indexToken?.prices?.minPrice
    : indexToken?.prices?.maxPrice

  const positionKey = getPositionKey(
    p.order.account,
    p.order.marketAddress,
    p.order.initialCollateralTokenAddress,
    p.order.isLong,
  )

  const existingPosition = getByKey(p.positionsData, positionKey)

  const executionFee = useMemo(() => {
    if (!p.order.isFrozen || !gasLimits || !gasPrice || !tokensData) {
      return undefined
    }

    let estimatedGas: BigNumber | undefined

    if (isSwapOrderType(p.order.orderType)) {
      estimatedGas = estimateExecuteSwapOrderGasLimit(gasLimits, {
        swapsCount: p.order.swapPath.length,
      })
    }

    if (isIncreaseOrderType(p.order.orderType)) {
      estimatedGas = estimateExecuteIncreaseOrderGasLimit(gasLimits, {
        swapsCount: p.order.swapPath.length,
      })
    }

    if (isDecreaseOrderType(p.order.orderType)) {
      estimatedGas = estimateExecuteDecreaseOrderGasLimit(gasLimits, {
        decreaseSwapType: p.order.decreasePositionSwapType,
        swapsCount: p.order.swapPath.length,
      })
    }

    if (!estimatedGas) {
      return undefined
    }
    const oraclePriceCount = estimateOrderOraclePriceCount(
      p.order.swapPath.length ?? 0,
    )

    return getExecutionFee(
      chainId,
      gasLimits,
      tokensData,
      estimatedGas,
      gasPrice,
      oraclePriceCount,
    )
  }, [
    chainId,
    gasLimits,
    gasPrice,
    p.order.decreasePositionSwapType,
    p.order.isFrozen,
    p.order.orderType,
    p.order.swapPath.length,
    tokensData,
  ])
  const subaccount = useSubaccount(executionFee?.feeTokenAmount ?? null)

  function getError() {
    if (isSubmitting) {
      return `Updating Order...`
    }

    if (isSwapOrderType(p.order.orderType)) {
      if (!triggerRatio?.ratio?.gt(0) || !minOutputAmount.gt(0)) {
        return `Enter a ratio`
      }

      if (minOutputAmount.eq(p.order.minOutputAmount)) {
        return `Enter a new ratio`
      }

      if (
        triggerRatio &&
        !isRatioInverted &&
        markRatio?.ratio.lt(triggerRatio.ratio)
      ) {
        return `Price above Mark Price`
      }

      if (
        triggerRatio &&
        isRatioInverted &&
        markRatio?.ratio.gt(triggerRatio.ratio)
      ) {
        return `Price below Mark Price`
      }

      return
    }

    const positionOrder = p.order as PositionOrderInfo

    if (!markPrice) {
      return `Loading...`
    }

    if (!sizeDeltaUsd?.gt(0)) {
      return `Enter an amount`
    }

    if (!triggerPrice?.gt(0)) {
      return `Enter a price`
    }

    if (
      sizeDeltaUsd?.eq(positionOrder.sizeDeltaUsd) &&
      triggerPrice?.eq(positionOrder.triggerPrice!)
    ) {
      return `Enter new amount or price`
    }

    if (isLimitOrderType(p.order.orderType)) {
      if (p.order.isLong) {
        if (triggerPrice?.gte(markPrice)) {
          return `Price above Mark Price`
        }
      } else {
        if (triggerPrice?.lte(markPrice)) {
          return `Price below Mark Price`
        }
      }
    }

    if (isTriggerDecreaseOrderType(p.order.orderType)) {
      if (!markPrice) {
        return `Loading...`
      }

      if (
        sizeDeltaUsd?.eq(p.order.sizeDeltaUsd || 0) &&
        triggerPrice?.eq(positionOrder.triggerPrice || 0)
      ) {
        return `Enter a new size or price`
      }

      if (existingPosition?.liquidationPrice) {
        if (
          existingPosition.isLong &&
          triggerPrice?.lte(existingPosition?.liquidationPrice)
        ) {
          return `Price below Liq. Price`
        }

        if (
          !existingPosition.isLong &&
          triggerPrice?.gte(existingPosition?.liquidationPrice)
        ) {
          return `Price above Liq. Price`
        }
      }

      if (p.order.isLong) {
        if (
          p.order.orderType === OrderType.LimitDecrease &&
          triggerPrice?.lte(markPrice)
        ) {
          return `Price below Mark Price`
        }

        if (
          p.order.orderType === OrderType.StopLossDecrease &&
          triggerPrice?.gte(markPrice)
        ) {
          return `Price above Mark Price`
        }
      } else {
        if (
          p.order.orderType === OrderType.LimitDecrease &&
          triggerPrice?.gte(markPrice)
        ) {
          return `Price above Mark Price`
        }

        if (
          p.order.orderType === OrderType.StopLossDecrease &&
          triggerPrice?.lte(markPrice)
        ) {
          return `Price below Mark Price`
        }
      }
    }
  }

  function getSubmitButtonState(): {
    text: string
    disabled?: boolean
    onClick?: () => void
  } {
    const error = getError()

    if (error) {
      return {
        text: error,
        disabled: true,
      }
    }

    const orderTypeName =
      p.order.orderType === OrderType.LimitIncrease
        ? `Limit`
        : getTriggerNameByOrderType(p.order.orderType)

    return {
      text: `Update ${orderTypeName} Order`,
      disabled: false,
      onClick: onSubmit,
    }
  }

  function onSubmit() {
    if (!signer || !address) {
      return
    }
    const positionOrder = p.order as PositionOrderInfo

    setIsSubmitting(true)

    updateOrderTxn(chainId, signer, subaccount, sendPaymasterTransaction, {
      account: address,
      orderKey: p.order.key,
      sizeDeltaUsd: sizeDeltaUsd || positionOrder.sizeDeltaUsd,
      triggerPrice: triggerPrice || positionOrder.triggerPrice,
      acceptablePrice: acceptablePrice || positionOrder.acceptablePrice,
      minOutputAmount: minOutputAmount || p.order.minOutputAmount,
      executionFee: executionFee?.feeTokenAmount,
      indexToken: indexToken,
      setPendingTxns: p.setPendingTxns,
    })
      .then(() => p.onClose())
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const submitButtonState = getSubmitButtonState()

  useEffect(
    function initValues() {
      if (isInited) {
        return
      }

      if (isSwapOrderType(p.order.orderType)) {
        const ratio = (p.order as SwapOrderInfo).triggerRatio

        if (ratio) {
          setTriggerRatioInputValue(formatAmount(ratio.ratio, USD_DECIMALS, 2))
        }
      } else {
        const positionOrder = p.order as PositionOrderInfo

        setSizeInputValue(
          formatAmountFree(positionOrder.sizeDeltaUsd || 0, USD_DECIMALS),
        )
        setTriggerPriceInputValue(
          formatAmount(
            positionOrder.triggerPrice || 0,
            USD_DECIMALS,
            indexPriceDecimals || 2,
          ),
        )
      }

      setIsInited(true)
    },
    [fromToken, indexPriceDecimals, isInited, p.order, sizeInputValue, toToken],
  )

  return (
    <Modal
      isOpen={true}
      onClose={p.onClose}
      title={`Edit ${p.order.title}`}
      hideClose={isMobile}
    >
      <div className="flex h-full flex-1 flex-col justify-between pt-4">
        <div>
          {!isSwapOrderType(p.order.orderType) && (
            <>
              <BuyInputSection
                topLeftLabel={
                  isTriggerDecreaseOrderType(p.order.orderType)
                    ? `Close`
                    : `Size`
                }
                inputValue={sizeInputValue}
                onInputValueChange={(e) => setSizeInputValue(e.target.value)}
              >
                USD
              </BuyInputSection>

              <BuyInputSection
                topLeftLabel={`Price`}
                topRightLabel={`Mark:`}
                topRightValue={formatUsd(markPrice, {
                  displayDecimals: indexPriceDecimals,
                })}
                // onClickTopRightLabel={() =>
                //   setTriggerPriceInputValue(
                //     formatAmount(
                //       markPrice,
                //       USD_DECIMALS,
                //       indexPriceDecimals || 2,
                //     ),
                //   )
                // }
                inputValue={triggerPirceInputValue}
                onInputValueChange={(e) =>
                  setTriggerPriceInputValue(e.target.value)
                }
              >
                USD
              </BuyInputSection>
            </>
          )}

          {isSwapOrderType(p.order.orderType) && (
            <>
              {triggerRatio && (
                <BuyInputSection
                  topLeftLabel={`Price`}
                  topRightValue={formatAmount(
                    markRatio?.ratio,
                    USD_DECIMALS,
                    4,
                  )}
                  // onClickTopRightLabel={() => {
                  //   setTriggerRatioInputValue(
                  //     formatAmount(markRatio?.ratio, USD_DECIMALS, 10),
                  //   )
                  // }}
                  inputValue={triggerRatioInputValue}
                  onInputValueChange={(e) => {
                    setTriggerRatioInputValue(e.target.value)
                  }}
                >
                  {`${triggerRatio.smallestToken.symbol} per ${triggerRatio.largestToken.symbol}`}
                </BuyInputSection>
              )}
            </>
          )}

          <div className="mt-3">
            {!isSwapOrderType(p.order.orderType) && (
              <>
                <ExchangeInfoRow
                  label={`Acceptable Price`}
                  value={
                    formatAcceptablePrice(
                      applySlippageToPrice(
                        allowedSlippage,
                        acceptablePrice || BigNumber.from(0),
                        true,
                        p.order.isLong,
                      ),
                      {
                        displayDecimals: indexPriceDecimals,
                      },
                    ) || '-'
                  }
                />

                {existingPosition && (
                  <ExchangeInfoRow
                    label={`Liq. Price`}
                    value={formatLiquidationPrice(
                      existingPosition.liquidationPrice,
                      {
                        displayDecimals: indexPriceDecimals,
                      },
                    )}
                  />
                )}
              </>
            )}

            {isSwapOrderType(p.order.orderType) && (
              <>
                <ExchangeInfoRow
                  label={`Swap Price Impact`}
                  value={
                    <span
                      className={cx({
                        positive:
                          p.order.swapPathStats?.totalSwapPriceImpactDeltaUsd?.gt(
                            0,
                          ),
                      })}
                    >
                      {formatDeltaUsd(
                        p.order.swapPathStats?.totalSwapPriceImpactDeltaUsd,
                      )}
                    </span>
                  }
                />

                <ExchangeInfoRow
                  label={`Swap Fees`}
                  value={formatUsd(p.order.swapPathStats?.totalSwapFeeUsd)}
                />

                <ExchangeInfoRow
                  label={`Min. Receive`}
                  value={formatTokenAmount(
                    minOutputAmount,
                    p.order.targetCollateralToken.decimals,
                    p.order.targetCollateralToken.symbol,
                  )}
                />
              </>
            )}

            {executionFee?.feeTokenAmount.gt(0) && (
              <ExchangeInfoRow label={`Max Execution Fee`}>
                {formatTokenAmount(
                  executionFee?.feeTokenAmount,
                  executionFee?.feeToken.decimals,
                  executionFee?.feeToken.symbol,
                  { displayDecimals: 5 },
                )}
              </ExchangeInfoRow>
            )}
          </div>
        </div>

        <Button
          className="w-full !font-flexo uppercase"
          onClick={submitButtonState.onClick}
          disabled={submitButtonState.disabled}
          size="large"
        >
          {submitButtonState.text}
        </Button>
      </div>
    </Modal>
  )
}
