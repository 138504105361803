import { useCallback } from 'react'
import cx from 'classnames'
import Image from 'next/image'
import IconButton from '@components/shared/Button'
import DownloadIcon from 'public/icons/csv-download.svg'

type Props<T> = {
  data: T[]
  excludedFields: string[]
  fileName: string
  className?: string
  variant?: 'standard' | 'small'
  customDownloadFn?: () => void
}

function filterFields<T>(data: T, excludedFields: string[]): Partial<T> {
  const result = { ...data }
  excludedFields.forEach((field: string) => delete (result as any)[field])
  return result
}

function convertToCSV<T>(data: Partial<T>[]): string {
  const header = Object.keys(data[0]).join(',')
  const values = data
    .map((object) => Object.values(object).join(','))
    .join('\n')
  return `${header}\n${values}`
}

export function DownloadAsCsv<T>({
  data,
  excludedFields,
  fileName,
  className,
  variant = 'standard',
  customDownloadFn,
}: Props<T>) {
  const getCsvUrl = useCallback(
    (data: T[]) => {
      const filteredData = data.map((item) =>
        filterFields(item, excludedFields),
      )
      const csv = convertToCSV(filteredData)
      return `data:application/octet-stream,${encodeURIComponent(csv)}`
    },
    [excludedFields],
  )

  const onClick = useCallback(() => {
    const csvUrl = getCsvUrl(data)
    const aElement = document.createElement('a')
    aElement.href = csvUrl
    aElement.download = `${fileName}.csv`
    document.body.appendChild(aElement)
    aElement.click()
    document.body.removeChild(aElement)
  }, [data, fileName, getCsvUrl])

  if (!data || data.length === 0) {
    return null
  }

  return variant === 'standard' ? (
    <IconButton
      className="flex h-10 w-auto cursor-pointer items-center !gap-4 rounded-md border border-th-fgd-3 p-3"
      onClick={customDownloadFn ? customDownloadFn : onClick}
    >
      <Image
        className={cx('clickable', className)}
        src={'/icons/download.svg'}
        alt="Download as CSV"
        width={24}
        height={24}
      />
      Download CSV
    </IconButton>
  ) : (
    <div
      className="flex cursor-pointer items-center gap-2 rounded border border-th-input-border bg-th-bkg-2 px-6 py-1 text-sm font-semibold leading-6 text-th-fgd-1"
      onClick={customDownloadFn ? customDownloadFn : onClick}
    >
      <DownloadIcon />
      CSV
    </div>
  )
}
