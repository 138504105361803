import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import Tooltip from '@components/shared/Tooltip'
import TableRowAccordian from '@components/TableRowAccordian'
import cx from 'classnames'
import clsx from 'clsx'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { useSettings } from 'context/SettingsContext/SettingsContextProvider'
import {
  MarketsInfoData,
  getMarketIndexName,
  getMarketPoolName,
} from 'domain/synthetics/markets'
import {
  OrderInfo,
  OrderType,
  PositionOrderInfo,
  SwapOrderInfo,
  isDecreaseOrderType,
  isIncreaseOrderType,
  isSwapOrderType,
} from 'domain/synthetics/orders'
import {
  PositionsInfoData,
  getTriggerNameByOrderType,
} from 'domain/synthetics/positions'
import {
  adaptToV1TokenInfo,
  convertToTokenAmount,
  convertToUsd,
} from 'domain/synthetics/tokens'
import { getMarkPrice } from 'domain/synthetics/trade'
import {
  USD_DECIMALS,
  getExchangeRate,
  getExchangeRateDisplay,
} from 'gmx/lib/legacy'
import { formatAmount, formatTokenAmount, formatUsd } from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import useTokenPrice from 'hooks/gmx/usePyth/useTokenPrice'
import { useViewport } from 'hooks/useViewport'
import { useMemo } from 'react'

type Props = {
  order: OrderInfo
  onSelectOrder?: () => void
  onEditOrder?: () => void
  onCancelOrder?: () => void
  isSelected?: boolean
  isCanceling?: boolean
  hideActions?: boolean
  marketsInfoData?: MarketsInfoData
  positionsInfoData?: PositionsInfoData
  isSwap?: boolean
}

export function OrderItem(p: Props) {
  const { isMobile } = useViewport()
  const { isSwap } = p
  const { showDebugValues } = useSettings()

  const indexTokenPrice = useTokenPrice(
    (p.order as PositionOrderInfo)?.indexToken?.pythId as string,
    (p.order as PositionOrderInfo)?.indexToken?.address,
    'maxPrice',
  )

  const isCollateralSwap =
    p.order.initialCollateralToken.address !==
    p.order.targetCollateralToken.address

  function getCollateralText() {
    const initialCollateralToken = p.order.initialCollateralToken
    const targetCollateralToken = p.order.targetCollateralToken

    const collateralUsd = convertToUsd(
      p.order.initialCollateralDeltaAmount,
      initialCollateralToken.decimals,
      initialCollateralToken.prices.minPrice,
    )

    const targetCollateralAmount = convertToTokenAmount(
      collateralUsd,
      targetCollateralToken.decimals,
      targetCollateralToken.prices.minPrice,
    )

    const tokenAmountText = formatTokenAmount(
      targetCollateralAmount,
      targetCollateralToken?.decimals,
      targetCollateralToken?.symbol,
    )

    return `${tokenAmountText}`
  }

  function getSwapRatioText() {
    if (!isSwapOrderType(p.order.orderType)) {
      return {}
    }

    const fromToken = p.order.initialCollateralToken
    const toToken = p.order.targetCollateralToken

    const fromTokenInfo = fromToken ? adaptToV1TokenInfo(fromToken) : undefined
    const toTokenInfo = toToken ? adaptToV1TokenInfo(toToken) : undefined

    const triggerRatio = (p.order as SwapOrderInfo).triggerRatio

    const markExchangeRate =
      fromToken && toToken
        ? getExchangeRate(
            adaptToV1TokenInfo(fromToken),
            adaptToV1TokenInfo(toToken),
            false,
          )
        : undefined

    const swapRatioText = `${formatAmount(
      triggerRatio?.ratio,
      USD_DECIMALS,
      triggerRatio?.smallestToken.isStable ? 2 : 4,
    )} ${triggerRatio?.smallestToken.symbol} / ${
      triggerRatio?.largestToken.symbol
    }`

    const markSwapRatioText = getExchangeRateDisplay(
      markExchangeRate,
      fromTokenInfo,
      toTokenInfo,
    )

    return { swapRatioText, markSwapRatioText }
  }

  function renderTitle() {
    if (isSwapOrderType(p.order.orderType) || isSwap) {
      if (showDebugValues) {
        return (
          <Tooltip
            content={
              <>
                <StatsTooltipRow
                  label={'Key'}
                  value={<div>{p.order.key}</div>}
                  showDollar={false}
                />
                <StatsTooltipRow
                  label={'Amount'}
                  value={<div>{p.order.minOutputAmount.toString()}</div>}
                  showDollar={false}
                />
              </>
            }
          >
            {renderTitleWithIcon(p.order)}
          </Tooltip>
        )
      }

      if (p.order.errors.length) {
        return (
          <Tooltip
            className={clsx(
              p.order.errorLevel === 'error'
                ? 'text-th-error'
                : p.order.errorLevel === 'warning'
                  ? 'text-th-warning'
                  : 'text-th-fgd-3',
            )}
            content={
              <>
                {p.order.errors.map((error, i) => (
                  <div
                    className={cx({
                      'OrderItem-tooltip-row': i > 0,
                    })}
                    key={error.msg}
                  >
                    <span
                      className={
                        error!.level === 'error' ? 'negative' : 'warning'
                      }
                    >
                      {error.msg}
                    </span>
                  </div>
                ))}
              </>
            }
          >
            {renderTitleWithIcon(p.order)}
          </Tooltip>
        )
      }

      return renderTitleWithIcon(p.order)
    }

    const positionOrder = p.order as PositionOrderInfo
    const indexName = getMarketIndexName(positionOrder.marketInfo)
    const poolName = getMarketPoolName(positionOrder.marketInfo)

    return (
      <Tooltip
        className={clsx(
          p.order.errorLevel === 'error'
            ? 'text-th-error'
            : p.order.errorLevel === 'warning'
              ? 'text-th-warning'
              : 'text-th-fgd-3',
        )}
        content={
          <>
            <StatsTooltipRow
              label={`Market`}
              value={
                <div className="items-center">
                  <span>{indexName && indexName}</span>
                  <span className="subtext lh-1">
                    {poolName && `[${poolName}]`}
                  </span>
                </div>
              }
              showDollar={false}
            />
            <StatsTooltipRow
              label={`Collateral`}
              value={getCollateralText()}
              showDollar={false}
            />

            {isCollateralSwap && (
              <div className="OrderItem-tooltip-row">
                <>
                  {formatTokenAmount(
                    p.order.initialCollateralDeltaAmount,
                    p.order.initialCollateralToken.decimals,
                    p.order.initialCollateralToken.symbol,
                  )}{' '}
                  will be swapped to {p.order.targetCollateralToken.symbol} on
                  order execution.
                </>
              </div>
            )}

            {showDebugValues && (
              <div className="OrderItem-tooltip-row">
                <StatsTooltipRow
                  label={'Key'}
                  value={<div>{positionOrder.key}</div>}
                  showDollar={false}
                />
              </div>
            )}

            {p.order.errors.length ? (
              <>
                {p.order.errors.map((error) => (
                  <div className="OrderItem-tooltip-row" key={error.msg}>
                    <span
                      className={
                        error!.level === 'error' ? 'negative' : 'warning'
                      }
                    >
                      {error.msg}
                    </span>
                  </div>
                ))}
              </>
            ) : null}
          </>
        }
      >
        {renderTitleWithIcon(p.order)}
      </Tooltip>
    )
  }

  function renderTitleWithIcon(order: OrderInfo) {
    if (isSwapOrderType(order.orderType) || isSwap) {
      const {
        initialCollateralToken,
        targetCollateralToken,
        minOutputAmount,
        initialCollateralDeltaAmount,
      } = order

      const fromTokenText = formatTokenAmount(
        initialCollateralDeltaAmount,
        initialCollateralToken.decimals,
        '',
      )

      const fromTokenWithIcon = (
        <span className="nobr flex items-center gap-1 px-2">
          <RenderTokenIcon symbol={initialCollateralToken.symbol} />
          {initialCollateralToken.symbol}
        </span>
      )

      const toTokenText = formatTokenAmount(
        minOutputAmount,
        targetCollateralToken.decimals,
        '',
      )

      const toTokenWithIcon = (
        <span className="nobr flex items-center gap-1">
          <RenderTokenIcon symbol={targetCollateralToken.symbol} />
          {targetCollateralToken.symbol}
        </span>
      )

      return (
        <span className="flex gap-1">
          Swap {fromTokenText} {fromTokenWithIcon} for {toTokenText}{' '}
          {toTokenWithIcon}
        </span>
      )
    } else {
      const marketInfo = getByKey(p.marketsInfoData, order.marketAddress)
      const indexToken = marketInfo?.indexToken
      const { orderType, isLong, sizeDeltaUsd } = order

      const longShortText = isLong ? `Long` : `Short`
      const sizeText = formatUsd(sizeDeltaUsd)
      const increaseOrDecreaseText = isIncreaseOrderType(orderType)
        ? `Increased`
        : `Decreased`

      return (
        <span
          className={clsx(
            'flex items-center',
            isIncreaseOrderType(orderType)
              ? 'text-th-success'
              : 'text-th-error',
          )}
        >
          {increaseOrDecreaseText} {indexToken?.symbol} {longShortText} by{' '}
          {sizeText}
        </span>
      )
    }
  }

  function renderTriggerPrice() {
    if (isSwapOrderType(p.order.orderType) || isSwap) {
      const toAmount = p.order.minOutputAmount
      const toToken = p.order.targetCollateralToken
      const toAmountText = formatTokenAmount(
        toAmount,
        toToken?.decimals,
        toToken?.symbol,
      )

      const { swapRatioText } = getSwapRatioText()

      return (
        <>
          {!p.hideActions ? (
            <Tooltip
              content={`You will receive at least ${toAmountText} if this order is executed. This price is being updated in real time based on Swap Fees and Price Impact.`}
            >
              {swapRatioText || '-'}
            </Tooltip>
          ) : (
            swapRatioText || '-'
          )}
        </>
      )
    } else {
      return (
        <Tooltip
          content={
            <>
              <StatsTooltipRow
                label={`Acceptable Price`}
                value={
                  positionOrder.orderType === OrderType.StopLossDecrease
                    ? 'NA'
                    : `${positionOrder.triggerThresholdType} ${formatUsd(
                        positionOrder.acceptablePrice,
                        {
                          displayDecimals: priceDecimals,
                        },
                      )}`
                }
                showDollar={false}
              />
            </>
          }
        >
          {`${positionOrder.triggerThresholdType} ${formatUsd(
            positionOrder.triggerPrice,
            {
              displayDecimals: priceDecimals,
            },
          )}`}
        </Tooltip>
      )
    }
  }

  const positionOrder = p.order as PositionOrderInfo
  const priceDecimals = positionOrder?.indexToken?.priceDecimals

  const markPrice = useMemo(() => {
    if (isSwapOrderType(p.order.orderType) || isSwap) {
      return undefined
    }
    return getMarkPrice({
      prices: { maxPrice: indexTokenPrice, minPrice: indexTokenPrice },
      isIncrease: isIncreaseOrderType(positionOrder.orderType),
      isLong: positionOrder.isLong,
    })
  }, [
    p.order.orderType,
    isSwap,
    indexTokenPrice,
    positionOrder.orderType,
    positionOrder.isLong,
  ])

  function renderMarkPrice() {
    if (isSwapOrderType(p.order.orderType) || isSwap) {
      const { markSwapRatioText } = getSwapRatioText()

      return markSwapRatioText || '-'
    } else {
      return (
        <Tooltip
          content={
            <>
              <p>
                The order will be executed when the oracle price is{' '}
                {positionOrder.triggerThresholdType}{' '}
                {formatUsd(positionOrder.triggerPrice, {
                  displayDecimals: priceDecimals,
                })}
                .
              </p>
              <p>
                Note that there may be rare cases where the order cannot be
                executed, for example, if the chain is down and no oracle
                reports are produced or if the price impact exceeds your
                acceptable price.
              </p>
            </>
          }
        >
          {formatUsd(markPrice, { displayDecimals: priceDecimals })}
        </Tooltip>
      )
    }
  }

  function renderLarge() {
    return (
      <tr className="table h-12 w-full table-fixed font-body">
        <td className="pl-6 text-sm font-semibold text-th-fgd-1">
          <div className="flex items-center gap-2">
            <RenderTokenIcon symbol={p.order.indexToken?.symbol} />
            <span className="text-sm font-semibold leading-[18px] !text-th-fgd-1">
              {p.order.indexToken!.symbol}
            </span>
          </div>
          {/* {isDecreaseOrderType(p.order.orderType)
            ? getTriggerNameByOrderType(positionOrder.orderType)
            : `Limit`} */}
        </td>
        <td className="text-sm font-semibold text-th-fgd-1" colSpan={1.5}>
          {isDecreaseOrderType(p.order.orderType)
            ? getTriggerNameByOrderType(positionOrder.orderType)
            : `Limit`}{' '}
        </td>
        <td className="text-sm font-semibold text-th-fgd-1">
          <span
            className={clsx(
              'capitalize',
              p.order.isLong ? 'text-th-success' : 'text-th-error',
            )}
          >
            {p.order.isLong ? `Long` : `Short`}
          </span>
        </td>
        <td className="text-sm font-semibold text-th-fgd-1" align="left">
          {formatUsd(p.order.sizeDeltaUsd)}
        </td>
        {/* <td colSpan={2}>{renderTitle()}</td> */}
        <td
          className="text-sm font-semibold text-th-fgd-1 opacity-90"
          align="left"
        >
          {renderTriggerPrice()}
        </td>
        <td
          className="pl-6 text-sm font-medium text-th-fgd-1 opacity-90"
          align="left"
        >
          {/* {renderMarkPrice()} */}
          {0.0}
        </td>
        <td
          className="pl-6 text-sm font-medium text-th-fgd-1 opacity-90"
          align="left"
        >
          {/* {formatUsd(p.order.netValue)} */}
          {'-'}
        </td>
        {!p.hideActions && (
          <td className="min-w-fit max-w-[204px] pr-6">
            <div className="flex items-center justify-end gap-2">
              {p.onEditOrder && (
                <button
                  className="flex h-5 min-w-fit items-center rounded-sm border border-[#56565A] px-3.5"
                  onClick={p.onEditOrder}
                >
                  <span className="text-xs font-semibold uppercase leading-3 tracking-normal text-[#56565A] opacity-90">
                    Edit
                  </span>
                </button>
              )}
              {p.onCancelOrder && (
                <button
                  className="flex h-5 min-w-fit items-center rounded-sm border border-[#56565A] px-1"
                  onClick={p.onCancelOrder}
                  disabled={p.isCanceling}
                >
                  <span className="text-xs font-semibold uppercase leading-3 tracking-normal text-[#56565A] opacity-90">
                    Cancel
                  </span>
                </button>
              )}
            </div>
          </td>
        )}
      </tr>
    )
  }

  function renderSmall() {
    return (
      <tr>
        <TableRowAccordian
          VisibleContent={
            <div className="flex items-center justify-between py-4">
              <div className="text-sm font-semibold capitalize text-th-fgd-1">
                {isDecreaseOrderType(p.order.orderType)
                  ? getTriggerNameByOrderType(positionOrder.orderType)
                  : `Limit`}
              </div>

              <div className="mr-2 flex items-center gap-3 text-sm font-medium text-th-fgd-1">
                <div className="flex flex-col">
                  <span className="text-[10px] font-normal text-th-fgd-2">
                    Trigger Price
                  </span>
                  <span className="text-sm font-medium text-th-fgd-1">
                    {renderTriggerPrice()}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[10px] font-normal text-th-fgd-2">
                    MARK PRICE
                  </span>
                  <span className="text-sm font-medium text-th-fgd-1">
                    {renderMarkPrice()}
                  </span>
                </div>
              </div>
            </div>
          }
          HiddenContent={
            <div className="flex flex-col gap-4 pb-4">
              <div className="flex items-start justify-between">
                <div className="flex flex-col">
                  <span className="text-[10px] font-normal text-th-fgd-2">
                    ORDER
                  </span>
                  <span className="text-sm font-medium text-th-fgd-1">
                    {renderTitle()}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-6">
                {!p.hideActions && (
                  <>
                    {p.onEditOrder && (
                      <button
                        className="flex flex-1 items-center justify-center gap-1 rounded border !border-th-fgd-5 py-2 !text-th-fgd-5"
                        onClick={p.onEditOrder}
                      >
                        EDIT
                      </button>
                    )}
                    {p.onCancelOrder && (
                      <button
                        className="flex flex-1 items-center justify-center gap-1 rounded border !border-th-fgd-5 py-2 !text-th-fgd-5"
                        onClick={p.onCancelOrder}
                        disabled={p.isCanceling}
                      >
                        CANCEL
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          }
        />
      </tr>
    )
  }

  return isMobile ? renderSmall() : renderLarge()
}
