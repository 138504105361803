import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import Tooltip from '@components/shared/Tooltip'
import cx from 'classnames'
import clsx from 'clsx'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { getExplorerUrl } from 'config/chains'
import dayjs from 'dayjs'
import { isSwapOrderType } from 'domain/synthetics/orders'
import {
  PositionTradeAction,
  SwapTradeAction,
  TradeAction,
} from 'domain/synthetics/tradeHistory'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { formatUsd } from 'gmx/lib/numbers'
import { MarketsInfoResult } from 'hooks/useMarketInfo'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { formatPositionMessage } from './utils/position'
import { TooltipContent, TooltipString } from './utils/shared'
import { formatSwapMessage } from './utils/swap'
import Image from 'next/image'

type Props = {
  tradeAction: TradeAction
  minCollateralUsd: BigNumber
  shouldDisplayAccount?: boolean
  showDebugValues?: boolean
  marketsInfoData?: MarketsInfoResult
  isSwap?: boolean
}

function LineSpan({ span }: { span: TooltipString }) {
  if (span === undefined) {
    return null
  }

  if (typeof span === 'string') {
    return <span>{span}</span>
  }

  return (
    <span
      className={cx({
        'text-th-error': span.state === 'error',
        'text-th-success': span.state === 'success',
        'text-th-fgd-2': span.state === 'muted',
      })}
    >
      {span.text}
    </span>
  )
}

function LineSpans({ spans }: { spans: TooltipString[] }) {
  return (
    <>
      {spans.map((span, i) => (
        <LineSpan key={i} span={span} />
      ))}
    </>
  )
}

function TooltipContentComponent({ content }: { content: TooltipContent }) {
  return (
    <div className="TradeHistoryRow-tooltip">
      {content?.map((line, i) => {
        if (line === undefined) {
          return null
        }

        if (line === '') {
          return <br key={i} />
        }

        if (typeof line === 'string') {
          return <div key={i}>{line}</div>
        }

        if (Array.isArray(line)) {
          return (
            <div key={i}>
              <LineSpans spans={line} />
            </div>
          )
        }

        if ('key' in line && 'value' in line) {
          return (
            <StatsTooltipRow
              key={i}
              label={line.key}
              value={<LineSpan span={line.value} />}
              showDollar={false}
            />
          )
        }

        return (
          <div key={i}>
            <LineSpan span={line} />
          </div>
        )
      })}
    </div>
  )
}

export function TradeHistoryRow({
  minCollateralUsd,
  tradeAction,
  shouldDisplayAccount,
  showDebugValues,
  marketsInfoData,
  isSwap,
}: Props) {
  const { chainId } = useChainId()

  const msg = useMemo(() => {
    if (isSwapOrderType(tradeAction.orderType)) {
      return formatSwapMessage(
        tradeAction as SwapTradeAction,
        marketsInfoData?.marketsInfoData,
      )
    }

    return formatPositionMessage(
      tradeAction as PositionTradeAction,
      minCollateralUsd,
    )
  }, [tradeAction, minCollateralUsd, marketsInfoData?.marketsInfoData])

  const renderTimestamp = useMemo(() => msg.timestampISO, [msg.timestampISO])

  const renderMarketContent = useMemo(() => {
    if (msg.indexName) {
      return (
        <StatsTooltipRow
          label={`Market`}
          value={
            <div className="items-center">
              <span>{msg.indexName!}</span>
              <span className="subtext lh-1">[{msg.poolName!}]</span>
            </div>
          }
          showDollar={false}
        />
      )
    }

    return (
      <>
        {msg.fullMarketNames?.map((market, index) => (
          <span key={market.indexName}>
            {index > 0 && ' → '}
            <span>{market.indexName}</span>
            <span className="subtext lh-1">[{market.poolName}]</span>
          </span>
        ))}
      </>
    )
  }, [msg.fullMarketNames, msg.indexName, msg.poolName])

  const renderPriceContent = useMemo(
    () => <TooltipContentComponent content={msg.priceComment} />,
    [msg.priceComment],
  )

  const renderActionTooltipContent = useMemo(
    () => <TooltipContentComponent content={msg.actionComment!} />,
    [msg.actionComment],
  )

  return (
    <>
      <tr className="table h-12 w-full table-fixed">
        <td className="pl-6" colSpan={1}>
          <Tooltip content={renderTimestamp}>
            <span className="text-sm font-medium text-th-fgd-1">
              {dayjs(msg.timestampISO).format('DD MMM YYYY')}
            </span>
          </Tooltip>
        </td>
        <td className="pl-6" colSpan={1}>
          <Tooltip content={renderTimestamp}>
            <span className="text-sm font-medium text-th-fgd-1">
              {dayjs(msg.timestampISO).format('hh:mm:ss a')}
            </span>
          </Tooltip>
        </td>
        <td colSpan={2}>
          <div className="flex">
            <ExternalLink
              className="TradeHistoryRow-external-link ml-xs"
              href={`${getExplorerUrl(chainId)}tx/${
                tradeAction.transaction.hash
              }`}
            >
              {msg.actionComment ? (
                <Tooltip
                  className={cx('TradeHistoryRow-action-handle', {
                    'Tooltip-error': msg.isActionError,
                  })}
                  content={renderActionTooltipContent}
                >
                  {msg.action}
                </Tooltip>
              ) : (
                <span
                  className={cx('TradeHistoryRow-action-handle', {
                    'text-th-error': msg.isActionError,
                  })}
                >
                  {msg.action}
                </span>
              )}
            </ExternalLink>
          </div>
          {shouldDisplayAccount && (
            <Link
              className="TradeHistoryRow-account"
              to={`/actions/${tradeAction.account}`}
            >
              {tradeAction.account}
            </Link>
          )}
        </td>

        <td colSpan={isSwap ? 1 : 2}>
          <Tooltip content={renderMarketContent} placement="right">
            {msg.indexToken ? (
              <div className="flex items-center gap-2">
                <RenderTokenIcon symbol={msg.indexToken.symbol} />
                <span className="text-sm font-semibold leading-[18px] text-th-fgd-1">
                  {msg.indexToken.symbol}/USD
                </span>
              </div>
            ) : (
              <span className="text-sm font-semibold leading-[18px] text-th-fgd-1">
                {msg.market}
              </span>
            )}
          </Tooltip>
        </td>

        {!isSwap && (
          <td>
            <span
              className={clsx(
                'capitalize',
                msg.longShortText === 'Long'
                  ? 'text-th-success'
                  : 'text-th-error',
              )}
            >
              {msg.longShortText}
            </span>
          </td>
        )}

        {!isSwap && (
          <td align="left">
            <span className="text-sm font-medium text-th-fgd-1">
              {msg.size}
            </span>
          </td>
        )}

        <td align="left">
          <span className="text-sm font-medium text-th-fgd-1">
            {formatUsd(tradeAction?.pnlUsd || BigNumber.from(0), {
              displayDecimals: 2,
            })}
          </span>
        </td>
        <td colSpan={isSwap ? 2 : 1} align="left">
          <Tooltip content={renderPriceContent}>
            {msg.price ? (
              <span className="text-sm font-medium text-th-fgd-1">
                {msg.price}
              </span>
            ) : (
              '-'
            )}
          </Tooltip>
        </td>

        <td colSpan={1} className="text-sm font-medium text-th-fgd-1">
          {formatUsd(
            tradeAction?.positionFeeAmount
              .add(tradeAction.borrowingFeeAmount)
              .add(tradeAction.borrowingFeeAmount),
            {
              displayDecimals: 2,
            },
          )}
        </td>

        <td colSpan={1} align="center">
          <ExternalLink
            className="TradeHistoryRow-external-link text-center"
            href={`${getExplorerUrl(chainId)}tx/${
              tradeAction.transaction.hash
            }`}
          >
            <Image
              alt=""
              className="mx-auto"
              src="/icons/explorer-icon.svg"
              width={24}
              height={24}
            />
          </ExternalLink>
        </td>
      </tr>
      {showDebugValues && (
        <tr>
          <td colSpan={4}>
            <div>Order Key: {tradeAction.orderKey}</div>
          </td>
        </tr>
      )}
    </>
  )
}
