import { CLOUD_FRONT_URL } from 'config/constants'
import Image from 'next/image'
import CalendarIcon from 'public/icons/calendar-icon.svg'
import { Dispatch, SetStateAction } from 'react'
import DatePicker, {
  CalendarContainer,
  ReactDatePickerProps,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaChevronDown } from 'react-icons/fa'

interface DateRangePickerProps {
  startDate: Date | null
  endDate: Date | null
  setStartDate: Dispatch<SetStateAction<Date | null>>
  setEndDate: Dispatch<SetStateAction<Date | null>>
  portalId?: string
}

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  ...rest
}: DateRangePickerProps & Partial<ReactDatePickerProps>) => {
  const handleDateChange = ([startDate, endDate]: [
    Date | null,
    Date | null,
  ]) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <DatePicker
      {...rest}
      onChange={(e) => handleDateChange(e)}
      startDate={startDate}
      endDate={endDate}
      customInput={
        <div className="flex items-center justify-center gap-2 rounded border border-th-input-border bg-th-bkg-2 px-4 py-1.5">
          <CalendarIcon />
          <span className="text-sm font-medium text-th-fgd-1">
            {startDate?.getFullYear()}-
            {startDate !== null ? startDate.getMonth() + 1 : '-'}-
            {startDate?.getDate()}
          </span>
          <span className="text-sm font-medium text-th-fgd-3">to</span>
          <span className="text-sm font-medium text-th-fgd-1">
            {endDate?.getFullYear()}-
            {endDate !== null ? endDate.getMonth() + 1 : '-'}-
            {endDate?.getDate()}
          </span>
          <span className="cursor-pointer">
            <FaChevronDown fontSize={14} color="#565656" />
          </span>
        </div>
      }
      selectsRange={true}
      monthsShown={2}
      maxDate={new Date()}
      calendarContainer={Container}
    >
      <div className="glass-background absolute bottom-0 left-0 flex w-full justify-between px-6 py-[10px]">
        <button
          className="rounded border border-th-input-border bg-th-bkg-5 px-3 py-2 text-th-button"
          onClick={() => {
            if (!startDate && !endDate) {
              setStartDate(new Date())
              setEndDate(new Date())
              return
            }

            setEndDate(new Date())
          }}
        >
          Today
        </button>

        <div className="flex items-center gap-2">
          <div className="w-[90px] rounded border border-th-input-border bg-th-bkg-5 px-3 py-2 text-center text-th-fgd-1">
            {startDate
              ? startDate.getFullYear() +
                '/' +
                (startDate.getMonth() + 1) +
                '/' +
                startDate.getDate()
              : '-'}
          </div>
          <div className="h-[1px] w-2 bg-th-fgd-3" />
          <div className="w-[90px] rounded border border-th-input-border bg-th-bkg-5 px-3 py-2 text-center text-th-fgd-1">
            {endDate
              ? endDate.getFullYear() +
                '/' +
                (endDate.getMonth() + 1) +
                '/' +
                endDate.getDate()
              : '-'}
          </div>
        </div>
      </div>
    </DatePicker>
  )
}
const Container = ({
  children,
  className,
}: {
  children: React.ReactNode
  className: string
}) => {
  return (
    <div className="relative">
      <div className="glassmorphism-bg" />
      <div className="glassmorphism" />
      <Image
        alt=""
        src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
        layout="fill"
        objectFit="cover"
      />
      <div className="absolute left-1/2 top-[55px] h-[200px] w-[1px] bg-th-bkg-3" />
      <CalendarContainer className={className}>
        <div>{children}</div>
      </CalendarContainer>
    </div>
  )
}

export default DateRangePicker
