export const COLORS: Record<string, Record<string, string>> = {
  BKG1: {
    Patek: '#111113',
    Stargazer: '#000805',
  },
  BKG2: {
    Patek: '#202020',
    Stargazer: '#262527',
  },
  BKG3: {
    Patek: '#2E2E2E',
    Stargazer: '#1A191B',
  },
  BKG4: {
    Patek: '#404040',
    Stargazer: '#404040',
  },
  BKG5: {
    Patek: '#09090A',
    Stargazer: '#09090A',
  },
  FGD3: {
    Patek: '#909096',
    Stargazer: '#909096',
  },
  ACTIVE: {
    Patek: '#696EFF',
    Stargazer: '#696EFF',
  },
  UP: {
    Patek: '#6ED5A3',
    Stargazer: '#00E100',
  },
  DOWN: {
    Patek: '#D56E6E',
    Stargazer: '#EF323F',
  },
}
