import { getTotalAccruedFundingUsd } from 'domain/synthetics/markets'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { useMemo } from 'react'
import { ClaimableCardUI } from './ClaimableCardUI'

type Props = {
  onSettleClick: () => void
  positionsInfoData: PositionsInfoData | undefined
}

const tooltipText = `Accrued Positive Funding Fees for Positions not yet claimable. They will become available to claim by using the "Settle" button, or after the Position is increased, decreased or closed.`
const buttonText = `Settle`
const title = `Accrued`

export function SettleAccruedCard({ onSettleClick, positionsInfoData }: Props) {
  const positions = useMemo(
    () => Object.values(positionsInfoData || {}),
    [positionsInfoData],
  )
  const fundingFees = useMemo(
    () => getTotalAccruedFundingUsd(positions),
    [positions],
  )

  return (
    <ClaimableCardUI
      fundingFees={fundingFees}
      buttonText={buttonText}
      title={title}
      tooltipText={tooltipText}
      onButtonClick={onSettleClick}
    />
  )
}
