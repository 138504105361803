import Tooltip from '@components/shared/Tooltip'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { IS_VERBOSE } from 'config/development'
import { getToken } from 'config/tokens'
import dayjs from 'dayjs'
import {
  ClaimAction,
  ClaimCollateralAction,
  ClaimFundingFeeAction,
  ClaimType,
} from 'domain/synthetics/claimHistory'
import {
  getMarketIndexName,
  getMarketPoolName,
} from 'domain/synthetics/markets'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { formatTokenAmount } from 'gmx/lib/numbers'
import { useViewport } from 'hooks/useViewport'
import { Fragment, useMemo } from 'react'
import { formatTradeActionTimestampISO } from '../TradeHistoryRow/utils/shared'

type ClaimHistoryRowProps = {
  claimAction: ClaimAction
}
type ClaimCollateralHistoryRowProps = {
  claimAction: ClaimCollateralAction
}

type ClaimFundingFeesHistoryRowProps = {
  claimAction: ClaimFundingFeeAction
}

const claimCollateralEventTitles: Record<
  ClaimCollateralAction['eventName'],
  string
> = {
  [ClaimType.ClaimFunding]: `Claim Funding Fees`,
  [ClaimType.ClaimPriceImpact]: `Claim Price Impact`,
}

export function ClaimHistoryRow({ claimAction }: ClaimHistoryRowProps) {
  return (
    <>
      {claimAction.type === 'collateral' ? (
        <ClaimCollateralHistoryRow claimAction={claimAction} />
      ) : (
        <ClaimFundingFeesHistoryRow claimAction={claimAction} />
      )}
    </>
  )
}

function ClaimCollateralHistoryRow(p: ClaimCollateralHistoryRowProps) {
  const { chainId } = useChainId()
  const { claimAction } = p

  const marketsCount = claimAction.claimItems.length

  const eventTitle = claimCollateralEventTitles[claimAction.eventName]

  const tokensMsg = useMemo(() => {
    const amountByToken = claimAction.claimItems.reduce(
      (acc, { marketInfo, longTokenAmount, shortTokenAmount }) => {
        if (longTokenAmount.gt(0)) {
          acc[marketInfo.longTokenAddress] =
            acc[marketInfo.longTokenAddress] || BigNumber.from(0)
          acc[marketInfo.longTokenAddress] =
            acc[marketInfo.longTokenAddress].add(longTokenAmount)
        }
        if (shortTokenAmount.gt(0)) {
          acc[marketInfo.shortTokenAddress] =
            acc[marketInfo.shortTokenAddress] || BigNumber.from(0)
          acc[marketInfo.shortTokenAddress] =
            acc[marketInfo.shortTokenAddress].add(shortTokenAmount)
        }

        return acc
      },
      {} as { [tokenAddress: string]: BigNumber },
    )

    const tokensMsg = Object.entries(amountByToken)
      .map(([tokenAddress, amount]) => {
        try {
          const token = getToken(chainId, tokenAddress)

          return formatTokenAmount(amount, token.decimals, token.symbol)
        } catch (e) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.log('[ClaimHistoryRow]', e)
          return undefined
        }
      })
      .filter(Boolean)

    return tokensMsg
  }, [chainId, claimAction.claimItems])

  return (
    <tr className="table h-12 w-full table-fixed">
      <td className="pl-6">
        {/* <ExternalLink
        className="plain"
        href={`${getExplorerUrl(chainId)}tx/${claimAction.transactionHash}`}
      > */}
        <span className="flex gap-2 text-sm font-medium leading-[18px] text-th-fgd-1">
          {eventTitle}
          <span className="mr-2 text-sm font-medium text-th-fgd-3">
            {dayjs(formatTradeActionTimestampISO(claimAction.timestamp)).format(
              'DD MMM YYYY, hh:mm a',
            )}
          </span>
        </span>
        {/* </ExternalLink> */}
      </td>

      <td>
        <span className="flex items-end gap-1 text-sm font-medium leading-[18px] text-th-fgd-1">
          {tokensMsg?.map((msg, i) => {
            const [amount, token] =
              msg?.split(/([A-Za-z]+)/).filter(Boolean) || []
            return (
              <div key={i}>
                <span>{amount}</span>
                <span className="text-th-fgd-2">
                  {token}
                  {tokensMsg.length - 1 !== i && ','}
                </span>
              </div>
            )
          })}
        </span>
      </td>
      <td>
        <Tooltip
          content={
            <>
              {claimAction.claimItems.map(
                (
                  { marketInfo: market, longTokenAmount, shortTokenAmount },
                  index,
                ) => {
                  const indexName = getMarketIndexName(market)
                  const poolName = getMarketPoolName(market)
                  return (
                    <Fragment key={market.indexTokenAddress}>
                      <StatsTooltipRow
                        key={market.marketTokenAddress}
                        label={
                          <div className="items-top">
                            <span>{indexName}</span>
                            <span className="subtext lh-1">[{poolName}]</span>
                          </div>
                        }
                        showDollar={false}
                        value={
                          <>
                            {longTokenAmount.gt(0) && (
                              <div>
                                {formatTokenAmount(
                                  longTokenAmount,
                                  market.longToken.decimals,
                                  market.longToken.symbol,
                                )}
                              </div>
                            )}

                            {shortTokenAmount.gt(0) && (
                              <div>
                                {formatTokenAmount(
                                  shortTokenAmount,
                                  market.shortToken.decimals,
                                  market.shortToken.symbol,
                                )}
                              </div>
                            )}
                          </>
                        }
                      />
                      {index < marketsCount - 1 && <br />}
                    </Fragment>
                  )
                },
              )}
            </>
          }
        >
          <span className="text-sm font-medium leading-[18px] text-th-fgd-1">
            {marketsCount ? '# Markets' : '# Market'}
          </span>
        </Tooltip>
      </td>
    </tr>
  )
}

const claimFundingFeeEventTitles: Record<
  ClaimFundingFeeAction['eventName'],
  string
> = {
  // [ClaimType.SettleFundingFeeCancelled]: `Failed Settlement of Funding Fees`,
  // [ClaimType.SettleFundingFeeCreated]: `Request Settlement of Funding Fees`,
  [ClaimType.SettleFundingFeeExecuted]: `Settled Funding Fees`,
}

function ClaimFundingFeesHistoryRow(p: ClaimFundingFeesHistoryRowProps) {
  const { claimAction } = p
  const { isMobile } = useViewport()

  const eventTitle = claimFundingFeeEventTitles[claimAction.eventName]

  const content = useMemo(() => {
    if (claimAction.eventName === ClaimType.SettleFundingFeeExecuted) {
      const indexName = getMarketIndexName(claimAction.markets[0])
      const poolName = getMarketPoolName(claimAction.markets[0])
      const amounts = claimAction.markets.map((market, index) => {
        const token = claimAction.tokens[index]
        const amount = claimAction.amounts[index]

        const [value, symbol] =
          formatTokenAmount(amount, token.decimals, token.symbol)
            ?.split(/([A-Za-z]+)/)
            .filter(Boolean) || []

        return (
          <Fragment key={`${token.address}/${market.marketTokenAddress}`}>
            <div>
              <span>{value}</span>
              <span className="text-th-fgd-2">{symbol}</span>
            </div>
            {index === claimAction.markets.length - 1 ? '' : ', '}
          </Fragment>
        )
      })
      const positionName = (
        <span className="items-top">
          {claimAction.isLongOrders[0] ? `Long` : `Short`} {indexName}
        </span>
      )
      const isLong = claimAction.isLongOrders[0]

      return (
        <tr className="table h-12 w-full table-fixed">
          <td className="pl-6">
            {/* <ExternalLink
        key={claimAction.id}
        className="plain flex w-full"
        href={`${getExplorerUrl(chainId)}tx/${claimAction.transactionHash}`}
      > */}
            <span className="flex gap-2 text-sm font-medium leading-[18px] text-th-fgd-1">
              {eventTitle}
              <span className="mr-2 text-sm font-medium text-th-fgd-3">
                {dayjs(
                  formatTradeActionTimestampISO(claimAction.timestamp),
                ).format('DD MMM YYYY, hh:mm a')}
              </span>
            </span>
            {/* </ExternalLink> */}
          </td>
          <td>
            <span className="text-sm font-medium leading-[18px] text-th-fgd-1">
              {amounts}
            </span>
          </td>
          <td>
            <Tooltip
              placement="top"
              content={
                <div className="items-center">
                  <span>{isLong ? `Long` : `Short`}</span>&nbsp;
                  <span>{indexName && indexName}</span>
                  <span className="subtext lh-1">
                    {poolName && `[${poolName}]`}
                  </span>
                </div>
              }
            >
              <p className="text-sm font-medium leading-[18px] text-th-fgd-1">
                {positionName} {!isMobile && <span>Position</span>}
              </p>
            </Tooltip>
          </td>
        </tr>
      )
    }

    return null
  }, [
    isMobile,
    claimAction.amounts,
    claimAction.eventName,
    claimAction.isLongOrders,
    claimAction.markets,
    claimAction.tokens,
    claimAction.timestamp,
    eventTitle,
  ])

  return <>{content}</>
}
