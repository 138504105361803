import {
  MarketsInfoData,
  getTotalClaimableFundingUsd,
} from 'domain/synthetics/markets'
import { ClaimableCardUI } from './ClaimableCardUI'

type Props = {
  onClaimClick: () => void
  marketsInfoData: MarketsInfoData | undefined
}

const tooltipText = `Positive Funding Fees for a Position become claimable after the Position is increased, decreased or closed; or settled its fees with the option under "Accrued".`
const buttonText = `Claim`
const title = `Claimed`

export function ClaimableCard({ marketsInfoData, onClaimClick }: Props) {
  const markets = Object.values(marketsInfoData ?? {})
  const totalClaimableFundingUsd = getTotalClaimableFundingUsd(markets)

  return (
    <ClaimableCardUI
      fundingFees={totalClaimableFundingUsd}
      buttonText={buttonText}
      title={title}
      tooltipText={tooltipText}
      onButtonClick={onClaimClick}
    />
  )
}
