import { ReactNode } from 'react'
import clsx from 'clsx'

type Props = {
  isChecked: boolean
  setIsChecked: (value: boolean) => void
  className?: string
  children?: ReactNode
}

export default function ToggleSwitch({
  isChecked,
  setIsChecked,
  className,
  children,
}: Props) {
  return (
    <div
      className={clsx('flex items-center justify-between text-sm', className)}
    >
      {children}
      <div
        className={clsx(
          'relative flex h-[18px] w-10 cursor-pointer items-center rounded-[50px] p-0.5 opacity-80 transition-all duration-200 ease-linear',
          isChecked ? 'bg-th-active' : 'bg-white/40',
        )}
        onClick={() => setIsChecked(!isChecked)}
      >
        <div
          className={clsx(
            'absolute bottom-0.5 h-3.5 w-3.5 rounded-full bg-th-button transition-all duration-200 ease-linear',
            isChecked ? 'left-6' : 'left-0.5',
          )}
        />
      </div>
    </div>
  )
}
