import DateRangePicker from '@components/shared/DateRangePicker'
import EmptyState from '@components/shared/EmptyState'
import TabsText from '@components/shared/TabsText'
import Checkbox from 'components/Checkbox/Checkbox'
import { DownloadAsCsv } from 'components/DownloadAsCSV/DownloadAsCSV'
import { TradeHistory } from 'components/Synthetics/TradeHistory/TradeHistory'
import dayjs from 'dayjs'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { PositionsInfoData } from 'domain/synthetics/positions'
import { TokensData } from 'domain/synthetics/tokens'
import { downloadCSV } from 'gmx/lib/utils'
import { Dispatch, SetStateAction, useState } from 'react'
import { cn } from 'utils/classnames'
import { Claims } from '../claims/Claims'
export interface HistoryCSV {
  Action: string
  'Action Link': string
  Date: string
  Market: string
  Size: string
  Price: string
}

export const transcastionStatusFilterOptions = [
  'Exclude Requests',
  'Exclude Modified',
  'Exclude Failed',
]

interface Props {
  account: string
  marketsInfoData: MarketsInfoData | undefined
  tokensData: TokensData | undefined
  positionsInfoData: PositionsInfoData | undefined
}

const History = ({
  account,
  marketsInfoData,
  tokensData,
  positionsInfoData,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState('Perps')
  const [transcastionStatusFilters, setTranscastionStatusFilters] = useState(
    transcastionStatusFilterOptions.map((val) => {
      return {
        label: val,
        isChecked: true,
      }
    }),
  )
  const [csvData, setCsvData] = useState<HistoryCSV[]>()
  const [startDate, setStartDate] = useState<Date | null>(
    dayjs(new Date()).subtract(1, 'M').toDate(),
  )
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const getTabs = () => {
    switch (selectedTab) {
      case 'Perps':
      case 'Swaps':
        return (
          <TradeHistory
            account={account}
            marketsInfoData={marketsInfoData}
            setCsvData={setCsvData}
            tokensData={tokensData}
            shouldShowPaginationButtons
            activeTab={selectedTab}
            filterDates={{
              startDate: startDate,
              endDate: endDate,
            }}
            transactionStatusFilters={transcastionStatusFilters}
          />
        )
      case 'LP':
        return (
          <EmptyState
            text={'No Data Found'}
            className="h-full max-sm:min-h-[30vh]"
          />
        )
      case 'Liquidations':
        return (
          <EmptyState
            text={'No Data Found'}
            className="h-full max-sm:min-h-[30vh]"
          />
        )
      case 'Claims':
        return (
          <Claims
            marketsInfoData={marketsInfoData}
            positionsInfoData={positionsInfoData}
            tokensData={tokensData}
            shouldShowPaginationButtons
          />
        )
      default:
        null
    }
  }

  return (
    <div className="h-full">
      <div className="flex h-12 items-center justify-between border-b border-th-input-border px-4">
        <TabsText
          activeTab={selectedTab}
          onChange={(v: string) => {
            setSelectedTab(v)
          }}
          tabs={[
            ['Perps', 0],
            ['Swaps', 0],
            ['LP', 0],
            ['Liquidations', 0],
            ['Claims', 0],
          ]}
        />
        <div className="invisible hidden items-center gap-4 sm:visible sm:flex">
          {selectedTab === 'Perps' && (
            <TranscastionStatusFilter
              options={transcastionStatusFilters}
              updateFiltersState={setTranscastionStatusFilters}
            />
          )}

          <div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>

          {csvData && (
            <DownloadAsCsv
              excludedFields={[]}
              fileName={'rfx_history_' + selectedTab + '_' + dayjs().format()}
              data={csvData}
              variant="small"
              customDownloadFn={() =>
                downloadCSV(
                  csvData,
                  'rfx_history_' + selectedTab + '_' + dayjs().format(),
                )
              }
            />
          )}
        </div>
      </div>
      <div className="sm:h-[calc(100%-48px)]">{getTabs()}</div>

      <div
        className={cn(
          'flex w-full flex-row items-center justify-between gap-2 px-4 pb-24 sm:invisible sm:hidden',
        )}
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        {csvData && (
          <DownloadAsCsv
            excludedFields={[]}
            fileName={'rfx_history_' + selectedTab + '_' + dayjs().format()}
            data={csvData}
            variant="small"
            customDownloadFn={() =>
              downloadCSV(
                csvData,
                'rfx_history_' + selectedTab + '_' + dayjs().format(),
              )
            }
          />
        )}
      </div>
    </div>
  )
}

type TransactionFilter = {
  label: string
  isChecked: boolean
}

export const TranscastionStatusFilter = ({
  options,
  updateFiltersState,
}: {
  options: TransactionFilter[]
  updateFiltersState: Dispatch<SetStateAction<TransactionFilter[]>>
}) => {
  const handleCheckboxChange = (label: string) => {
    updateFiltersState((prevOptions) =>
      prevOptions.map((option) =>
        option.label === label
          ? { ...option, isChecked: !option.isChecked }
          : option,
      ),
    )
  }
  return (
    <div className="flex items-center gap-4">
      {options.map(({ label, isChecked }) => {
        return (
          <Checkbox
            key={label}
            isChecked={isChecked}
            size="small"
            setIsChecked={() => handleCheckboxChange(label)}
          >
            <span className="font-semibold">{label}</span>
          </Checkbox>
        )
      })}
    </div>
  )
}

export default History
