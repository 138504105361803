import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TableRowAccordian from '@components/TableRowAccordian'
import { useMarketsInfo } from 'domain/synthetics/markets'
import { isSwapOrderType } from 'domain/synthetics/orders'
import {
  PositionTradeAction,
  SwapTradeAction,
  TradeAction,
} from 'domain/synthetics/tradeHistory'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { useMemo } from 'react'
import { cn } from 'utils/classnames'
import { formatPositionMessage } from './utils/position'
import { formatSwapMessage } from './utils/swap'

type Props = {
  tradeAction: TradeAction
  minCollateralUsd: BigNumber
  isSwap?: boolean
}

const TradeHistoryRowMobile = ({ tradeAction, minCollateralUsd }: Props) => {
  const { chainId } = useChainId()
  const { marketsInfoData } = useMarketsInfo(chainId)

  const msg = useMemo(() => {
    if (isSwapOrderType(tradeAction.orderType)) {
      return formatSwapMessage(tradeAction as SwapTradeAction, marketsInfoData)
    }

    return formatPositionMessage(
      tradeAction as PositionTradeAction,
      minCollateralUsd,
    )
  }, [marketsInfoData, minCollateralUsd, tradeAction])

  return (
    <TableRowAccordian
      VisibleContent={
        <div className={cn('h-10 w-full pr-4 tracking-normal')}>
          <div className={cn('flex w-full flex-row')}>
            <div className={cn('flex grow flex-row justify-between')}>
              <MobileLabelValue label="Date">
                05 Jun 2024 11:39:36 pm
              </MobileLabelValue>

              <MobileLabelValue label="L/S" className={cn('items-end')}>
                <div
                  className={cn(
                    msg.longShortText === 'Long'
                      ? 'text-th-success'
                      : 'text-th-error',
                  )}
                >
                  {msg.longShortText}
                </div>
              </MobileLabelValue>
            </div>

            <div className={cn('w-[100px]')}>
              <MobileLabelValue label="Size" className={cn('items-end')}>
                {msg.size}
              </MobileLabelValue>
            </div>
          </div>
        </div>
      }
      HiddenContent={
        <div className={cn('pr-4 pt-6 tracking-normal')}>
          {/* First Row Hidden */}
          <div className={cn('h-10 w-full pr-4')}>
            <div className={cn('flex w-full flex-row')}>
              <div className={cn('flex grow flex-row justify-between')}>
                <MobileLabelValue label="Action">
                  {msg.action.split(' ')[0]}
                </MobileLabelValue>

                <MobileLabelValue label="Market" className={cn('items-end')}>
                  <div className={cn('flex flex-row gap-1')}>
                    <RenderTokenIcon
                      symbol={msg.indexToken?.symbol}
                      size="small"
                    />
                    {msg.indexName}
                  </div>
                </MobileLabelValue>
              </div>

              <div className={cn('w-[100px]')}>
                <MobileLabelValue label="Price" className={cn('items-end')}>
                  {msg.price}
                </MobileLabelValue>
              </div>
            </div>
          </div>

          {/* Second Row Hidden */}
          <div className={cn('h-16 w-full pr-4 pt-6')}>
            <div className={cn('flex w-full flex-row')}>
              <div className={cn('flex grow flex-row justify-between')}>
                <div></div>

                <MobileLabelValue label="Value" className={cn('items-end')}>
                  <div className={cn('flex flex-row gap-1')}>{msg.value}</div>
                </MobileLabelValue>
              </div>

              <div className={cn('w-[100px]')}>
                <MobileLabelValue
                  label="Execution Price"
                  className={cn('items-end')}
                >
                  {msg.executionPrice || '--'}
                </MobileLabelValue>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

function MobileLabelValue({
  label,
  children,
  className,
}: {
  className?: string
  label: string
  children: React.ReactNode
}) {
  return (
    <div className={cn('flex h-10 flex-col gap-1', className)}>
      <span className="text-[10px] font-normal uppercase text-th-fgd-2">
        {label}
      </span>
      <span className="text-sm font-medium text-th-fgd-1">{children}</span>
    </div>
  )
}

export default TradeHistoryRowMobile
