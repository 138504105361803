import Tooltip from '@components/shared/Tooltip'
import clsx from 'clsx'
import { BigNumber } from 'ethers'
import { formatDeltaUsd } from 'gmx/lib/numbers'
import { useViewport } from 'hooks/useViewport'
import { useMemo } from 'react'

type Props = {
  fundingFees: BigNumber
  buttonText: string
  title: string
  onButtonClick: () => void
  tooltipText: string
}
export function ClaimableCardUI({
  buttonText,
  fundingFees,
  onButtonClick,
  title,
  tooltipText,
}: Props) {
  const totalUsd = useMemo(() => formatDeltaUsd(fundingFees), [fundingFees])
  const { isMobile } = useViewport()

  return (
    <div>
      <div className="grid h-8 grid-cols-4 items-center border-th-input-border bg-th-bkg-3 sm:border-b xl:grid-cols-3">
        <div className="col-span-1 pl-4 text-sm font-bold text-th-fgd-1 sm:px-6">
          {title}
        </div>
      </div>
      <div className="grid h-9 grid-cols-2 items-center justify-between border-th-bkg-3 pt-1 sm:h-12 sm:border-b sm:pt-0">
        <div
          className={clsx(
            'flex items-center gap-2 pl-6 text-sm font-medium text-th-fgd-1',
          )}
        >
          <span className="text-xs font-normal uppercase leading-[15px] text-th-fgd-3">
            Funding fees
          </span>
        </div>
        <div
          className={clsx(
            'text-xs font-normal uppercase leading-[15px] text-th-fgd-3',
            'flex justify-end pr-4',
          )}
        >
          Action
        </div>
      </div>
      <div className="grid h-9 grid-cols-2 items-center justify-between border-th-bkg-3 pb-1 sm:h-12 sm:border-b sm:pb-0">
        <div
          className={clsx(
            'flex items-center gap-2 pl-6 text-sm font-medium text-th-fgd-1',
          )}
        >
          <span>
            <Tooltip content={tooltipText}>
              <span>{totalUsd}</span>
            </Tooltip>
          </span>
        </div>
        <div className={clsx('col-span-1 flex justify-end pr-4')}>
          {fundingFees.gt(0) && (
            <button
              className={clsx(
                'h-6 rounded border px-2 font-semibold uppercase',
                isMobile
                  ? 'text-th-fgd-3/90 border-th-input-border text-sm leading-[14px]'
                  : 'border-[#56565A] text-xs text-[#56565A]',
              )}
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
