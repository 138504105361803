import EmptyState from '@components/shared/EmptyState'
import RfxLoader from '@components/shared/RfxLoader'
import { SortableColumnHeader } from '@components/shared/TableElements'
import { MarketsInfoData, useMarketsInfo } from 'domain/synthetics/markets'
import { usePositionsConstants } from 'domain/synthetics/positions/usePositionsConstants'
import { TokensData } from 'domain/synthetics/tokens'
import {
  PositionTradeAction,
  SwapTradeAction,
  useTradeHistory,
} from 'domain/synthetics/tradeHistory'
import { useChainId } from 'gmx/lib/chains'
import { useSortableData } from 'hooks/useSortableData'
import { useViewport } from 'hooks/useViewport'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { TradeHistoryRow } from '../TradeHistoryRow/TradeHistoryRow'
import TradeHistoryRowMobile from '../TradeHistoryRow/TradeHistoryRowMobile'
import clsx from 'clsx'
import { HistoryCSV } from '@components/account/Tabs/History'
import { getExplorerUrl } from 'config/chains'
import { formatPositionMessage } from '../TradeHistoryRow/utils/position'
import { formatSwapMessage } from '../TradeHistoryRow/utils/swap'
import { isSwapOrderType } from 'domain/synthetics/orders'
import { cn } from 'utils/classnames'

const PAGE_SIZE = 100

const getEventNameFromTransaction = (label: string) => {
  if (label === 'Failed Trascations' || label === 'Exclude Failed') {
    return 'OrderCancelled'
  }
  if (label === 'Exclude Requests') {
    return 'OrderCreated'
  }
  if (label === 'Exclude Modified') {
    return 'OrderUpdated'
  }
  if (label === 'Exclude Modified') {
    return 'OrderUpdated'
  }

  return ''
}

type Props = {
  shouldShowPaginationButtons: boolean
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  account: string | null | undefined
  forAllAccounts?: boolean
  activeTab: string
  classNames?: string
  setCsvData?: Dispatch<SetStateAction<HistoryCSV[] | undefined>>
  filterDates?: {
    startDate: Date | null
    endDate: Date | null
  }
  hasFilters?: boolean
  transactionStatusFilters?: {
    label: string
    isChecked: boolean
  }[]
}

export function TradeHistory(p: Props) {
  const {
    shouldShowPaginationButtons,
    marketsInfoData,
    tokensData,
    forAllAccounts,
    activeTab,
    setCsvData,
    filterDates,
    hasFilters,
    transactionStatusFilters,
  } = p

  const { chainId } = useChainId()
  const [pageIndex, setPageIndex] = useState(0)
  const { isMobile } = useViewport()
  const [activeFilter, setActiveFilter] = useState('All')
  const { minCollateralUsd } = usePositionsConstants(chainId)

  const isSwap = useMemo(() => {
    return activeTab === 'Swap'
  }, [activeTab])

  const includeExcludeFilters = useMemo(() => {
    const includeExclude: {
      includes: string[]
      excludes: string[]
    } = {
      includes: [],
      excludes: [],
    }
    transactionStatusFilters?.map((transaction) => {
      if (transaction.label.includes('Exclude')) {
        if (transaction.isChecked) {
          includeExclude.excludes.push(
            getEventNameFromTransaction(transaction.label),
          )
        }
      } else {
        if (transaction.isChecked) {
          includeExclude.includes.push(
            getEventNameFromTransaction(transaction.label),
          )
        }
      }
    })

    return includeExclude
  }, [transactionStatusFilters])

  const { tradeActions, isLoading: isHistoryLoading } = useTradeHistory(
    chainId,
    {
      account: p.account,
      forAllAccounts,
      marketsInfoData,
      tokensData,
      pageIndex,
      pageSize: PAGE_SIZE,
      isSwap,
      startDate: filterDates?.startDate as Date,
      endDate: filterDates?.endDate as Date,
      activeFilter,
      includeExcludeFilters,
    },
  )

  const marketsInfo = useMarketsInfo(chainId)

  const isLoading = !!p.account && (!minCollateralUsd || isHistoryLoading)

  const isEmpty = !tradeActions?.length

  const {
    items: tradeActionsData,
    requestSort,
    sortConfig,
  } = useSortableData(tradeActions || [])

  useEffect(() => {
    if (tradeActionsData && minCollateralUsd && marketsInfo && setCsvData) {
      const csvData: HistoryCSV[] = []

      tradeActionsData.map((tradeAction) => {
        if (isSwapOrderType(tradeAction.orderType!)) {
          const msg = formatSwapMessage(
            tradeAction as SwapTradeAction,
            marketsInfo.marketsInfoData,
          )
          csvData.push({
            Action: msg.action,
            'Action Link': `${getExplorerUrl(chainId)}tx/${
              tradeAction.transaction.hash
            }`,
            Date: msg.timestamp,
            Market: msg.market,
            Size: msg.value,
            Price: msg?.price || '-',
          })
          return
        }

        const msg = formatPositionMessage(
          tradeAction as PositionTradeAction,
          minCollateralUsd,
        )

        csvData.push({
          Action: msg.action,
          'Action Link': `${getExplorerUrl(chainId)}tx/${
            tradeAction.transaction.hash
          }`,
          Date: msg.timestamp,
          Market: msg.market,
          Size: msg.value,
          Price: msg?.price || '-',
        })

        return
      })

      setCsvData(csvData)
    }
  }, [tradeActionsData, minCollateralUsd])

  const hasToRenderFilters = useMemo(() => {
    return !isSwap && hasFilters
  }, [isSwap, hasFilters])

  if (isEmpty) {
    return (
      <div className="flex h-full justify-center max-sm:min-h-[30vh]">
        {isLoading ? (
          <RfxLoader />
        ) : (
          <EmptyState text={isSwap ? 'No Swap History' : 'No Trades Yet'} />
        )}
      </div>
    )
  }

  return (
    <div className="h-full">
      {isMobile ? (
        <div className={cn('flex flex-col gap-6 py-4')}>
          {tradeActions.map((tradeAction) => (
            <TradeHistoryRowMobile
              key={tradeAction.id}
              tradeAction={tradeAction}
              minCollateralUsd={minCollateralUsd!}
              isSwap={isSwap}
            />
          ))}
        </div>
      ) : (
        <>
          {hasToRenderFilters && (
            <div className="inline-flex h-12 w-full items-center justify-start gap-6 border-b border-th-input-border pl-6">
              {['All', 'Modified Orders', 'Cancelled Orders'].map((item) => (
                <div key={item}>
                  <div
                    className={clsx(
                      "font-['TT Commons Pro'] w-fit cursor-pointer text-sm font-semibold leading-[18px] text-neutral-400",
                      activeFilter === item && '!text-th-fgd-1',
                    )}
                    onClick={() => setActiveFilter(item)}
                  >
                    {item}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div
            className={clsx(
              hasToRenderFilters ? 'h-[calc(100%-48px)]' : 'h-full',
            )}
          >
            <table className={clsx('w-full border-collapse text-left')}>
              <thead className="table w-full table-fixed">
                <tr className="h-12 border-b border-th-input-border">
                  <th className="pl-6" colSpan={1}>
                    <SortableColumnHeader
                      sortKey="transaction.timestamp"
                      sort={() => requestSort('transaction.timestamp')}
                      sortConfig={sortConfig}
                      title={'Date'}
                    />
                  </th>
                  <th className="pl-6" colSpan={1}>
                    <SortableColumnHeader
                      sortKey="transaction.timestamp"
                      sort={() => requestSort('transaction.timestamp')}
                      sortConfig={sortConfig}
                      title={'Time'}
                    />
                  </th>
                  <th colSpan={2}>
                    <SortableColumnHeader
                      sortKey="eventName"
                      sort={() => requestSort('eventName')}
                      sortConfig={sortConfig}
                      title={'Action'}
                    />
                  </th>

                  <th colSpan={isSwap ? 1 : 2}>
                    <SortableColumnHeader
                      sortKey={
                        isSwap
                          ? `targetCollateralToken.symbol`
                          : `indexToken.symbol`
                      }
                      sort={() =>
                        requestSort(
                          isSwap
                            ? `targetCollateralToken.symbol`
                            : `indexToken.symbol`,
                        )
                      }
                      sortConfig={sortConfig}
                      title={'Market'}
                    />
                  </th>

                  {!isSwap && (
                    <th>
                      <SortableColumnHeader
                        sortKey="isLong"
                        sort={() => requestSort('isLong')}
                        sortConfig={sortConfig}
                        title={'SIDE'}
                      />
                    </th>
                  )}
                  {!isSwap && (
                    <th align="left">
                      <SortableColumnHeader
                        sortKey={isSwap ? 'minOutputAmount' : 'sizeDeltaUsd'}
                        sort={() =>
                          requestSort(
                            isSwap ? 'minOutputAmount' : 'sizeDeltaUsd',
                          )
                        }
                        sortConfig={sortConfig}
                        title={'Size'}
                      />
                    </th>
                  )}

                  <th>
                    <SortableColumnHeader
                      sortKey="pnlUsd"
                      sort={() => requestSort('pnlUsd')}
                      sortConfig={sortConfig}
                      title={'PNL'}
                    />
                  </th>
                  <th colSpan={isSwap ? 2 : 1} align="left">
                    <SortableColumnHeader
                      sortKey={isSwap ? 'minOutputAmount' : 'sizeDeltaUsd'}
                      sort={() =>
                        requestSort(isSwap ? 'minOutputAmount' : 'sizeDeltaUsd')
                      }
                      sortConfig={sortConfig}
                      title={'Price'}
                    />
                  </th>

                  <th colSpan={1}>
                    <SortableColumnHeader
                      sortKey="positionFeeAmount"
                      sort={() => requestSort('positionFeeAmount')}
                      sortConfig={sortConfig}
                      title={'Fees'}
                    />
                  </th>

                  <th align="center" colSpan={1}>
                    <SortableColumnHeader
                      sortKey="eventName"
                      sort={() => requestSort('eventName')}
                      sortConfig={sortConfig}
                      title={'Execution Tx'}
                    />
                  </th>
                </tr>
              </thead>
            </table>
            <div className="h-[calc(100%-40px)] overflow-y-scroll">
              <table className="w-full text-left">
                <tbody>
                  {tradeActionsData?.map((tradeAction) => {
                    return (
                      <TradeHistoryRow
                        shouldDisplayAccount={forAllAccounts}
                        marketsInfoData={marketsInfo}
                        key={tradeAction.id}
                        tradeAction={tradeAction}
                        minCollateralUsd={minCollateralUsd!}
                        isSwap={isSwap}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {shouldShowPaginationButtons && (
        <div className="flex items-center justify-center gap-6 p-4">
          {pageIndex > 0 && (
            <button onClick={() => setPageIndex((old) => old - 1)}>Prev</button>
          )}
          {tradeActions && tradeActions.length >= PAGE_SIZE && (
            <button onClick={() => setPageIndex((old) => old + 1)}>Next</button>
          )}
        </div>
      )}
    </div>
  )
}
