import mangoStore from '@store/mangoStore'
import useMangoAccount from 'hooks/useMangoAccount'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import { IconButton } from './Button'
import Tooltip from './Tooltip'

const ManualRefresh = ({
  classNames,
  hideBg = false,
  size,
}: {
  classNames?: string
  hideBg?: boolean
  size?: 'small' | 'medium' | 'large'
}) => {
  const { t } = useTranslation('common')
  const [spin, setSpin] = useState(false)
  const actions = mangoStore((s) => s.actions)
  const { mangoAccountAddress } = useMangoAccount()

  const handleRefreshData = async () => {
    setSpin(true)
    await actions.fetchGroup()
    if (mangoAccountAddress) {
      await actions.reloadMangoAccount()
      actions.fetchOpenOrders()
    }
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (spin) {
      timer = setTimeout(() => setSpin(false), 5000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [spin])

  return (
    <div className={`${classNames} rounded-full`}>
      <Tooltip content={t('refresh-data')} className="py-1 text-xs">
        <IconButton
          hideBg={hideBg}
          onClick={handleRefreshData}
          disabled={spin}
          size={size}
          className="cursor-pointer border-none bg-none"
        >
          <div className={`flex h-5 w-6 items-center justify-center`}>
            <Lottie
              path="/icons/lotties/refresh.json"
              loop={spin}
              play={spin}
            />
          </div>
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default ManualRefresh
