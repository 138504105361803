import { MarketInfo } from 'domain/synthetics/markets'
import { OrderType } from 'domain/synthetics/orders'
import { BigNumber, Bytes } from 'ethers'
import { TokenData } from '../tokens'

export enum TradeActionType {
  OrderCreated = 'OrderCreated',
  OrderExecuted = 'OrderExecuted',
  OrderCancelled = 'OrderCancelled',
  OrderUpdated = 'OrderUpdated',
  OrderFrozen = 'OrderFrozen',
}

export type RawTradeAction = {
  id: string
  eventName: TradeActionType

  account: string
  marketAddress?: string
  swapPath?: string[]
  initialCollateralTokenAddress?: string

  initialCollateralDeltaAmount?: string
  sizeDeltaUsd?: string
  triggerPrice?: string
  acceptablePrice?: string
  executionPrice?: string
  minOutputAmount?: string
  executionAmountOut?: string

  priceImpactDiffUsd?: string
  priceImpactUsd?: string
  positionFeeAmount?: string
  borrowingFeeAmount?: string
  fundingFeeAmount?: string
  pnlUsd?: string
  basePnlUsd?: string

  collateralTokenPriceMax?: string
  collateralTokenPriceMin?: string

  indexTokenPriceMin?: string
  indexTokenPriceMax?: string

  orderType: OrderType
  orderKey: string
  isLong?: boolean
  shouldUnwrapNativeToken?: boolean

  reason?: string
  reasonBytes?: Bytes

  transaction: {
    timestamp: number
    hash: string
  }
}

export type PositionTradeAction = {
  quoteAssetSize?: BigNumber
  acceptablePrice: BigNumber
  account: string
  basePnlUsd?: BigNumber
  borrowingFeeAmount: BigNumber
  collateralTokenPriceMax?: BigNumber
  collateralTokenPriceMin?: BigNumber
  eventName: TradeActionType
  executionPrice?: BigNumber
  fundingFeeAmount: BigNumber
  id: string
  indexToken: TokenData
  indexTokenPriceMax?: BigNumber
  indexTokenPriceMin?: BigNumber
  initialCollateralDeltaAmount: BigNumber
  initialCollateralToken: TokenData
  initialCollateralTokenAddress: string
  isLong: boolean
  marketAddress: string
  marketInfo: MarketInfo
  minOutputAmount: BigNumber
  orderKey: string
  orderType: OrderType
  pnlUsd?: BigNumber
  positionFeeAmount: BigNumber
  priceImpactDiffUsd?: BigNumber
  priceImpactUsd?: BigNumber
  reason?: string
  reasonBytes?: Bytes
  sizeDeltaUsd: BigNumber
  swapPath: string[]
  targetCollateralToken: TokenData
  triggerPrice?: BigNumber

  transaction: {
    timestamp: number
    hash: string
  }
}

export type SwapTradeAction = {
  id: string
  account: string
  eventName: TradeActionType
  initialCollateralTokenAddress: string
  initialCollateralToken: TokenData
  targetCollateralToken: TokenData
  shouldUnwrapNativeToken: boolean
  swapPath: string[]
  initialCollateralDeltaAmount: BigNumber
  minOutputAmount: BigNumber
  executionAmountOut?: BigNumber
  orderType: OrderType
  orderKey: string
  pnlUsd?: BigNumber

  reason?: string
  reasonBytes?: Bytes

  fundingFeeAmount: BigNumber
  positionFeeAmount: BigNumber
  borrowingFeeAmount: BigNumber

  transaction: {
    timestamp: number
    hash: string
  }
}

export type TradeAction = PositionTradeAction | SwapTradeAction
