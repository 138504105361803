import { BsArrowRight } from 'react-icons/bs'

type Props = {
  from?: React.ReactNode
  to?: React.ReactNode
}

export function ValueTransition(p: Props) {
  if (!p.to || p.to === p.from) {
    return <>{p.from}</>
  }
  if (!p.from) {
    return <>{p.to}</>
  }

  return (
    <div className="flex items-center gap-2">
      {p.from}
      <BsArrowRight />
      {p.to}
    </div>
  )
}
