import { useTranslation } from 'react-i18next'

const TabsText = ({
  tabs,
  activeTab,
  onChange,
  className = '',
  disabledTabIndex,
}: {
  tabs: [string, number][]
  activeTab: string
  onChange: (tab: string) => void
  className?: string
  disabledTabIndex?: number
}) => {
  const { t } = useTranslation(['common', 'trade'])
  return (
    <div className="flex space-x-6 text-sm sm:text-base">
      {tabs.map((tab, idx) => (
        <button
          className={`flex items-center space-x-2 font-medium focus:outline-none disabled:cursor-not-allowed ${
            activeTab === tab[0]
              ? 'cursor-text text-th-button'
              : 'text-th-fgd-3'
          } ${className}`}
          onClick={() => onChange(tab[0])}
          key={tab[0]}
          disabled={disabledTabIndex === idx}
        >
          <span>{t(tab[0])}</span>
          {tab[1] ? (
            <div className="rounded-md bg-th-bkg-3 px-1.5 py-0.5 font-body text-sm font-semibold leading-[18px] text-th-fgd-2">
              <span>{tab[1]}</span>
            </div>
          ) : null}
        </button>
      ))}
    </div>
  )
}

export default TabsText
