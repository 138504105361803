import { CLOUD_FRONT_URL } from 'config/constants'
import { BigNumber } from 'ethers'

export const getAssetFromCloudFront = (assetPath: string) => {
  return `${CLOUD_FRONT_URL}/${assetPath}`
}

export function getPositiveOrNegativeClass(
  value?: BigNumber,
  zeroValue: '' | 'text-th-error' | 'text-th-success' = '',
): string {
  if (!value) {
    return ''
  }
  return value.isZero()
    ? zeroValue
    : value.isNegative()
      ? 'text-th-error'
      : 'text-th-success'
}

export function getPlusOrMinusSymbol(
  value?: BigNumber,
  opts: { showPlusForZero?: boolean } = {},
): string {
  if (!value) {
    return ''
  }

  const { showPlusForZero = false } = opts
  return value.isZero()
    ? showPlusForZero
      ? '+'
      : ''
    : value.isNegative()
      ? '-'
      : '+'
}

export function downloadCSV(data: any[], filename: string = 'data.csv') {
  if (data.length === 0) {
    return
  }

  const headers = Object.keys(data[0])

  const csvContent = [
    headers.join(','),
    ...data.map((row) =>
      headers
        .map((header) => {
          const cell = row[header]
            ? String(row[header]).replace(/"/g, '""')
            : ''
          return `"${cell}"`
        })
        .join(','),
    ),
  ].join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
export const getShorterAddress = (addr: string) =>
  addr.substring(0, 5) + '...' + addr.substring(addr.length - 5, addr.length)
