import CountContainer from '@components/shared/CountContainer'
import clsx from 'clsx'
import { TradeMode } from 'domain/synthetics/trade'
import { useTranslation } from 'next-i18next'

type Values = string
interface TabButtonsProps<T extends Values> {
  activeValue: T
  onChange: (x: T) => void
  values: [T, number][]
  showBorders?: boolean
  rounded?: boolean
  fillWidth?: boolean
  activeClass?: string
  title?: Record<string, string>
}

const TabButtons = <T extends Values>({
  activeValue,
  values,
  onChange,
  showBorders = false,
  rounded = false,
  fillWidth = false,
  activeClass,
  title,
}: TabButtonsProps<T>) => {
  const { t } = useTranslation(['common', 'swap', 'token', 'trade', 'borrow'])

  return (
    <div className="flex w-full text-th-fgd-3">
      {values.map(([label, count], i) => (
        <div className={fillWidth ? 'flex-1' : ''} key={`${label}` + i}>
          <button
            className={`relative flex h-12 w-full items-center justify-center px-4 focus-visible:bg-th-bkg-2 focus-visible:text-th-button ${
              rounded ? 'rounded-md' : 'rounded-none'
            } ${
              showBorders
                ? fillWidth && i === values.length - 1
                  ? 'border-r-0'
                  : 'border-r border-th-input-border'
                : ''
            } ${
              label === activeValue
                ? label === 'buy'
                  ? 'bg-th-up-dark font-display !text-th-fgd-3'
                  : label === 'sell'
                    ? 'bg-th-down-dark font-display !text-th-fgd-3'
                    : 'z-10 bg-th-bkg-3'
                : 'hover:cursor-pointer hover:text-th-fgd-2'
            }`}
            key={`${label}${i}`}
            onClick={() => onChange(label)}
          >
            <span
              className={clsx(
                'whitespace-nowrap',
                label === 'buy' || label === 'sell'
                  ? 'sm:font-display'
                  : 'sm:font-bold',
                label === activeValue
                  ? 'text-th-fgd-1'
                  : 'text-th-fgd-3' + (activeClass || ''),
                'font-semibold',
              )}
            >
              {t(title ? title[label] : label)}
            </span>

            {count ? (
              <div
                className={clsx(
                  'ml-2.5 font-flexo text-xs leading-3 text-th-fgd-3',
                  label === activeValue && 'text-th-fgd-1',
                )}
              >
                <CountContainer
                  value={count}
                  color={label === activeValue ? '#fff' : '#909096'}
                  textClasses={
                    label === activeValue ? 'text-th-fgd-1' : 'text-th-fgd-3'
                  }
                />
              </div>
            ) : null}
            {label === TradeMode.Limit ? (
              <span
                className={`ml-2 rounded px-1 py-0.5 text-xxs font-medium uppercase leading-none ${
                  label === activeValue
                    ? 'bg-th-fgd-1 text-th-bkg-1'
                    : 'bg-th-bkg-3 text-th-fgd-2'
                }`}
              >
                beta
              </span>
            ) : null}
          </button>
        </div>
      ))}
    </div>
  )
}

export default TabButtons
